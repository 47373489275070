<template>
    <v-dialog v-model="unmappedShow" max-width="400" persistent>
        <v-card>
            <v-card-title class="headline">Mitarbeiter Zuordnung auflösen</v-card-title>
            <v-card-text>
                <h4>Wollen Sie wirklich die Zuordnung von</h4>
                <h3>{{  this.employee_to_mapping.other_name }}</h3>
                <h4>zu</h4>
                <h3>{{  this.employee_to_mapping.master_name }}</h3>
                <h4>auflösen?</h4>
            </v-card-text>
            <v-card-actions>
                <v-btn color="green darken-1" @click="acceptDialog">Bestätigen</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" @click="closeDialog">Abbrechen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>    

export default {
    data() {
        return {
            employee_to_mapping: '',
            unmappedShow: false
        }
    },
    methods: {
        /**
         * Close dialog
         */
        closeDialog() {
            this.unmappedShow = false;
        },

        /**
         * Accept dialog
         */
        acceptDialog() {
            this.unmappedShow = false;
            this.$emit('update_unmapped', true);
        },

        /**
         * Open dialog for check unmapping employee
         * @param employee_to_mapping 
         */
        openUnmappedDialog(employee_to_mapping) {
            this.unmappedShow = true;
            this.employee_to_mapping = employee_to_mapping;
        }
    }
}

</script>