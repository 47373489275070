<script src="../classes/APIItem.js"></script>

<template>
  <v-container fluid v-if="isLoggedIn == true">
    <h3>Artikel Mapping</h3>
    <v-tabs>
      <v-tab @click="changeTabs(false)">
        Nicht Bestätigt
      </v-tab>
      <v-tab @click="changeTabs(true)">
        Bestätigt
      </v-tab>
    </v-tabs>
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field class="mt-8" label="Suchen" dense solo-inverted clearable v-model="searchText"
          clear-icon="mdi-close-circle-outline"></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-select class="mt-8" :items="groups" label="Waregruppe" dense solo-inverted clearable v-model="searchText"
          clear-icon="mdi-close-circle-outline"></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <!--<v-select
            class="mt-8"
            :items="status"
            label="Status"
            dense
            solo-inverted
            clearable
            v-model="searchText"
            clear-icon="mdi-close-circle-outline"
        ></v-select>-->
      </v-col>
      <v-col cols="12" md="1">
        <v-btn class="mt-8" dense @click="refreshItems()">
          <v-icon color="#067800">
            mdi-refresh
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table dense :headers="headers" :items="items" item-key="name" :footer-props="{
              'items-per-page-options': [5, 10, 50, 100],
              'items-per-page-text': 'Einträge pro Seite',
              'disable-pagination': false,
              'show-current-page': true,
              'prev-icon': 'mdi-chevron-left',
              'next-icon': 'mdi-chevron-right',
            }" :search="searchText" show-select :items-per-page="10" v-model="selected" :loading="loading"
          @update:options="fetchNextItems">
          <template #[`item.data-table-select`]="{ item, isSelected, select }">
            <v-simple-checkbox :value="isSelected" :readonly="item.disabled" :disabled="item.disabled"
              @input="select($event)"></v-simple-checkbox>
          </template>
          <template #[`item.status`]="{ item }">
            <v-icon dense color="#a2a832" v-if="item.status ==='pending_assignment'">
              mdi-timer-sand
            </v-icon>
            <v-icon dense color="#067800" v-if="item.status ==='assigned'">
              mdi-check-circle-outline
            </v-icon>
            <v-icon dense color="#b02525" v-if="item.status ==='not_assignable'">
              mdi-help-rhombus-outline
            </v-icon>
          </template>
          <template #[`item.name`]="{ item }">
            <div>
              <div style="display: flex;">
                <p class="font-weight-medium" margin="0" padding="0" style="margin-bottom: 0">{{ item.name }}
                </p>
                <p class="font-italic font-weight-thin" style="margin-bottom: 0; margin-left: 2px" margin="0">
                  - {{ item.warengruppe }}
                </p>
              </div>
              <p class="font-italic font-weight-thin" style="margin-bottom: 0" margin="0">d.p {{ item.avg_price }}€,
                l.p. {{ item.last_price }}€</p>
            </div>
          </template>
          <template #[`item.product_group`]="{ item }">
            <v-select color="#067800" v-if="item.name" v-model="item.product_group" :items="groups"
              :disabled="item.disabled" dense>
            </v-select>
          </template>
          <template #[`item.action`]="{ item }">
            <v-btn dense height="20px" @click="saveItem(item)" :disabled="item.disabled">
              <v-icon color="#067800" v-if="item" dense>
                mdi-check
              </v-icon>
            </v-btn>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-select :disabled="selected.length === 0" :items="groups" label="Waregruppe" dense solo-inverted clearable
          v-model="massSaveGroup" clear-icon="mdi-close-circle-outline"></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <v-btn :disabled="massSaveGroup === null || selected.length === 0" @click="saveSelectedItems()">
          <v-icon color="#067800">
            mdi-check
          </v-icon>
          Massen Speichern
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>


<style>
html {
  overflow-y: auto;
}

</style>

<script>
import {ProductItem} from "@/classes/ProductItem.js";
import ApiDialog from "@/components/dialogs/ApiDialog.vue";
import axios from 'axios';

import apiMixin from '@/mixins/apiMixin.js';

const emitter = require('tiny-emitter/instance');

export default {
  name: "item-management-view",

  mixins: [apiMixin],

  mounted() {
    this.getBwaGroups();
    this.generateItems();
    const sessionInfo = localStorage.getObject("sessionInfo");
    if (sessionInfo != null) {
      this.name = sessionInfo.KeyCloakParam.KeyCloakUserInfo.LoginName;
      this.$emit("updateCount");
    }
  },
  methods: {
    refreshItems() {
      this.lastItemfetch = false;
      this.offset = 0;
      this.newItems = [];
      this.generateItems();
    },
    async getBwaGroups() {
      try {
        let api = axios.create({
          baseURL: process.env.VUE_APP_BASE_URL,
          timeout: 100000000
        });

        const response = await api.get(`GetBWAItems`);

        this.groups = response.data.map(e => e.name);
      } catch (error) {
        console.error("Fehler beim Abrufen der API-Elemente:", error); //TODO: Use Roman's error handling
      }
    },
    async getItems() {
      if (this.lastItemfetch) {
        return [];
      } else {
        try {
          let api = axios.create({
            baseURL: process.env.VUE_APP_BASE_URL,
            timeout: 100000000
          });

          const response = await api.get(`GetSalesItems`, {
            params: {
              echtId: this.SelectedGroup,
              offset: this.offset,
              limit: this.limit,
              assigned: this.assigned
            }
          });


          if (response.data.length === 0) {
            this.lastItemfetch = true;
          }

          return response.data
        } catch (error) {
          console.error("Fehler beim Abrufen der API-Elemente:", error); //TODO: Use Roman's error handling
        }
      }
    },
    async generateItems() {
      try {
        this.loading = true;
        const responseData = await this.getItems();

        responseData.forEach((item, i) => {
          const isDuplicate = this.newItems.some(existingItem => existingItem.name === item.title);

          if (!isDuplicate) {
            this.newItems.push(new ProductItem(
                i,
                this.SelectedGroup,
                item.title,
                item.bwa,
                item.status,
                item.avg_price,
                item.last_price,
                item.warengruppe
            ));
          }
        });

        this.items = this.newItems
        this.loading = false;
      } catch (error) {
        console.error("Fehler beim Abrufen der API-Elemente:", error); // TODO: Use Roman's error handling
      }
    },
    selectItem(item) {
      this.selectedItem = item;
      this.showApiDialog = true;
    },
    async saveItem(item) {
      if (item.name != null && item.product_group != null) {
        try {
          let api = axios.create({
            baseURL: process.env.VUE_APP_BASE_URL,
            timeout: 100000000
          });

          const response = await api.get(`SetSalesItems`, {
            params: {
              echtId: this.SelectedGroup,
              bwa: item.product_group,
              title: item.name
            }
          });
          //drop item from list
          if (item.status !== 'assigned') {
            //this.items = this.items.filter(e => e !== item);
            item.status = 'assigned';
            item.disabled = true;
            emitter.emit('updateUnmappedItemsCount');
          }
          return response.data
        } catch (error) {
          console.error("Fehler beim Abrufen der API-Elemente:", error); // TODO: Use Roman's error handling
        }
      } else {
        console.log("Item not complete"); // TODO: Use Roman's error handling
      }
    },
    saveSelectedItems() {
      this.selected.map(e => e).forEach((item, i) => {
        item.product_group = this.massSaveGroup;
        this.saveItem(item);
      });
      this.selected = [];
    },
    fetchNextItems() {
      if (this.canFetchNextItems) {
        this.canFetchNextItems = false;
        this.offset += this.limit;
        this.generateItems();
      } else {
        setTimeout(() => {
          this.canFetchNextItems = true;
        }, 500);
      }
    },
    async changeTabs(assigned) {
      this.lastItemfetch = false;
      this.offset = 0;
      this.assigned = assigned;
      try {
        this.newItems = [];
        await this.generateItems();
      } catch (error) {
        console.error("Fehler beim Abrufen der API-Elemente:", error); // TODO: Use Roman's error handling
      }
    }
  },
  props: {
    isLoggedIn: Boolean,
    SelectedGroup: Number
  },
  watch: {
    SelectedGroup(newVal) {
      if (newVal) {
        this.lastItemfetch = false;
        this.offset = 0;
        this.newItems = [];
        this.generateItems();
      }
    }
  },
  data() {
    return {
      searchText: '',
      massSaveGroup: null,
      items: [],
      newItems: [],
      selected: [],
      showApiDialog: false,
      selectedItem: null,
      limit: 250,
      offset: 0,
      loading: true,
      headers: [
        {
          text: "Status",
          align: "start",
          width: 20,
          sortable: true,
          value: "status",
        },
        {
          text: "Artikel",
          align: "start",
          width: 200,
          sortable: true,
          value: "name",
        },
        {
          text: "Waregruppe",
          align: "start",
          width: 50,
          sortable: true,
          value: "product_group",
        },
        {
          text: "Aktion",
          align: "start",
          width: 200,
          sortable: false,
          value: "action",
        },
      ],
      groups: [],
      status: [
        'assigned',
        'not_assignable',
        'pending_assignment',
      ],
      assigned: false,
      canFetchNextItems: true,
      lastItemfetch: false
    };
  },
  components: {
    ApiDialog
  }
}


</script>
