<template>
  <v-tooltip bottom v-if="canExecute" color="primary">
    <template v-slot:activator="{ on, attrs }">
      <v-btn            
        @click="execute()"
        :disabled="isDisabled"
        color="primary"
        icon  
        v-bind="attrs"
        v-on="on"
      >
        <v-icon x-small v-if="PreIconName">{{PreIconName}}</v-icon>
        <v-icon>{{IconName}}</v-icon>
      </v-btn>
    </template>
    <span>{{TooltipText}}</span>
  </v-tooltip>
</template>
<script>

export default {
  name: 'menu-command',
  created() {
  },
  mounted() {
  },
  props: {
    CommandName: String,
    IconName: String,
    PreIconName: String,
    TooltipText: String,
  },    
  methods: {
    execute() {
      this.$emit("Execute", this.CommandName);
    },
    updateCanExecute(routerView) {
      if (routerView == null) {
        return;
      }
      if (routerView[this.CommandName + "_isInvisible"] != undefined) {
        this.canExecute = routerView[this.CommandName + "_isInvisible"]() == false;  
      } else {
        this.canExecute = routerView[this.CommandName] != undefined;
      }
      this.isDisabled = false;
      if (routerView[this.CommandName + "_isDisabled"] != undefined) {
        this.isDisabled = routerView[this.CommandName + "_isDisabled"]();
      }
    }
  },
  data: () => ({
    canExecute: false,
    isDisabled: true
  }),
};
</script>
