<template>
  <div>
    <div
        v-show="reportFound === true && renewingSession === false"
        ref="container"
        :style="getStyle"
    ></div>
    <div v-show="reportFound === false" class="pa-8">
      Der Report <b>{{ reportName }}</b> konnte nicht gefunden werden.
    </div>
  </div>
</template>
<style scoped>
</style>
<script>
import {models} from "powerbi-client";
import * as pbi from "powerbi-client";
import apiMixin from "@/mixins/apiMixin.js";

export default {
  name: "pbiReportContainer",
  mixins: [apiMixin],
  props: {SelectedGroups: []},
  data: () => ({
    reportName: "",
    embedUrl: "",
    report: null,
    reportMobile: null,
    isReportLoaded: false,
    isReportRendered: false,
    viewType: 0,
    reportFound: true,
    renewingSession: false,
  }),
  created() {
  },
  mounted() {
    this.reportName = this.$route.params.reportName;
    this.pageName = this.$route.params.pageName;
    this.createAndInitReport();
    //Der Event orientationchange ist eigentlich depracted,
    //aber leider funktioniert die neue Screen API mit Safari nicht
    //siehe https://developer.mozilla.org/en-US/docs/Web/API/ScreenOrientation
    //daher wird vorläufig doch orientationchange verwendet
    window.addEventListener("orientationchange", this.handleOrientationChange);
  },
  watch: {
    $route: function () {
      this.reportName = this.$route.params.reportName;
      this.pageName = this.$route.params.pageName;
      this.createAndInitReport();
    },
    SelectedGroups: function (newVal, oldVal) {
      this._setFilter(newVal, oldVal);
    },
  },

  computed: {
    getStyle() {
      return `
      background-color:
      transparent;
      width:100%;
      display:flex;
      align-items:center;
      justify-content: center;
      height:${this.reportContentHeight}px`;
    },
  },
  methods: {
    _setFilter(newVal, oldVal) {
      console.log("_setFilter");
      console.log(newVal);
      if (newVal == undefined || newVal == null || newVal == 0) {
        return;
      }
      if (newVal !== oldVal) {
        this.report.setFilters(
            [
              {
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                  table: "Partners",
                  column: "EchtId"
                },
                operator: "In",
                values: newVal,
                filterType: 1
              }
            ]
        );
      }
    },

    handleOrientationChange() {
      this.initContainer(true);
    },

    createAndInitReport() {
      const sessionInfo = localStorage.getObject("sessionInfo");
      const reportInfo = sessionInfo.EmbedParam.EmbedReport.find(
          (r) => r.ReportName == this.reportName
      );
      if (reportInfo == null) {
        this.reportFound = false;
        return;
      }
      this.reportFound = true;
      this.embedUrl = reportInfo.EmbedUrl;

      this.initContainer(true);
      if (this.report) {
        this.report.setPage(this.pageName);
      }

      this.report.on('rendered', () => {
        //this._setFilter(this.SelectedGroups, 0);
        this.report.off('rendered');
      });
    },

    refresh() {
      if (this.reportFound === true) this.report.refresh();
    },

    fullscreen() {
      this.report.fullscreen();
    },

    async refreshTokenAndReloadPage() {
      this.renewingSession = true;
      await this.refreshSessionOrLogin();
      this.renewingSession = false;
      this.initContainer(true);
    },

    async initContainer(reset) {
      const me = this;
      const sessionInfo = localStorage.getObject("sessionInfo");
      if (!sessionInfo) return;

      const reportLoadConfig = {
        type: "report",
        tokenType: models.TokenType.Embed,
        accessToken: sessionInfo.EmbedParam.EmbedToken.Token,
        embedUrl: this.embedUrl,
        pageName: this.pageName,
        settings: {
          // layoutType: this.viewType == 0 ? models.LayoutType.MobilePortrait : models.LayoutType.Master,
          background: models.BackgroundType.Transparent,
          panes: {
            filters: {
              visible: false,
            },
            pageNavigation: {
              visible: false,
            },
          },
        },
        filters:
            [
              {
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                  table: "Partners",
                  column: "EchtId"
                },
                operator: "In",
                values: [],
                filterType: 1
              },
            ]
      };

      if(this.SelectedGroups !== undefined) {
        reportLoadConfig.filters[0].values = this.SelectedGroups;
      }

      if (this.isMobile && this.isPortrait) {
        reportLoadConfig.settings.layoutType = models.LayoutType.MobilePortrait;
      }

      const reportContainer = this.$refs.container;
      let powerbi = new pbi.service.Service(
          pbi.factories.hpmFactory,
          pbi.factories.wpmpFactory,
          pbi.factories.routerFactory
      );
      if (reset === true) {
        powerbi.reset(reportContainer);
      }

      me.report = powerbi.embed(reportContainer, reportLoadConfig);
      me.report.off("error");

      // Handle embed errors
      me.report.on("error", function (event) {
        event.preventDefault();
        event.stopPropagation();
        if (
            event.detail.errorCode == "403" ||
            event.detail.errorCode == "401"
        ) {
          me.refreshTokenAndReloadPage();
          return;
        } else {
          console.error("other report error");
          console.error(event.detail);
        }
      });

      me.report.off("loaded");
      me.report.on("loaded", function () {
        me.isReportLoaded = true;
      });

      me.report.off("rendered");
      me.report.on("rendered", function () {
        me.isReportRendered = true;
      });
    },
  },
};
</script>
