<template>
  <v-container fluid id="susaFiles">
  <v-card dense class="mt-4 px-4 pt-6 pb-2 d-flex" >
    <v-subheader class="pl-0 mr-4 pb-3" outline>Filter:</v-subheader>
      <v-autocomplete dense label="Jahr"
        style="min-width:80px;width:200px"
        v-model="jahr"
        :items="jahre"
        @change="onJahrChanged($event)">
      </v-autocomplete>
      <v-autocomplete class="ml-8" dense flex="1" style="width:1400px;min-width:100px"
        label="Gastrobetrieb"
        :items="partners"
        v-model="echtId"
        item-text="HandelsPartner"
        item-value="EchtId"
        @change="onGastrobetriebChanged($event)">
      </v-autocomplete>
  <v-card-text >
  </v-card-text>
  </v-card>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      item-key="Dateiname"
      display-text="Dateiname"
      class="mt-8"
      :items-per-page="itemsPerPage"
      hide-default-footer
    >
    <template #[`item.hasFile`]="{ item }">
      <v-icon color="green" v-if="item.hasFile==true">
        mdi-check-bold
      </v-icon>
    </template>
    <template #[`item.fileName`]="{ item }">
      <a target="_blank" v-if="item.hasFile==true" @click="download(item)">
        {{item.fileName}}
      </a>
    </template>
    <template #[`item.upload`]="{ item }">
      <v-btn small icon @click="uploadByItem(item)"><v-icon>mdi-file-upload</v-icon></v-btn>
    </template>
    <template #[`item.delete`]="{ item }">
      <v-btn small icon @click="deleteByItem(item)" v-if="item.hasFile==true"><v-icon>mdi-delete</v-icon></v-btn>
    </template>
    <template slot="no-data" v-if="loading == true">Lade Daten...</template>
    <template slot="no-data" v-if="loading == false">Keine Daten gefunden.</template>
    </v-data-table>
    <uploadSusaDialog ref="uploadSusaDlg" @fileUploaded="onFileUploaded($event)"></uploadSusaDialog>
    <v-overlay opacity="0" v-if="loading">
      <v-progress-circular
        indeterminate
        color="red"
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<style lang="scss">
#employees tr.v-data-table__selected {
  background: green !important;
}
</style>
<script>
import refData from '../staticData/referenceData.js';
import uploadSusaDialog from '../components/dialogs/UploadSusaDialog.vue';
import apiMixin from '@/mixins/apiMixin.js';
import fileSaver from 'file-saver';
import axios from 'axios';

export default {
  name: "upload-susa-view",

  mixins: [apiMixin],

  components: {
    uploadSusaDialog
  },

  data: () => ({
    loading: false,
    itemsPerPage: -1,
    items: [],
    headers: [],
    jahre: [],
    jahr: null,
    echtId: null,
    partners: []
  }),

  async created() {
    const me = this;
    me.loading = false;

    const sessionInfo = localStorage.getObject("sessionInfo");
    me.partners = sessionInfo.KeyCloakParam.KeyCloakPartners;
    me.jahre = refData.getJahre();

    const jahr = localStorage.getItem("jahr");
    me.jahr = jahr != null ? parseInt(jahr) : new Date().getFullYear();
    const echtId = localStorage.getItem("echtId");
    me.echtId = echtId != null ? parseInt(echtId) : me.partners[0].EchtId;


    me.headers = me.createHeaders();
    me.refresh();
  },

  methods: {

    onGastrobetriebChanged(e) {
      localStorage.setItem("echtId", e);
      this.refresh();
    },

    onJahrChanged(e) {
      localStorage.setItem("jahr", e);
      this.refresh();
    },

    async refresh() {
      const me = this;
      try {
        me.loading = true;
        const p = {
          jahr: me.jahr,
          echtId: me.echtId
        };
        const res = await me.api.get("GetSusaFiles",
          {
            params: p
          });
        me.items = null;
        me.items = res.data;
      } finally {
        me.loading = false;
      }
    },

    async onFileUploaded(e) {
      const me = this;
      me.jahr = e.jahr;
      localStorage.setItem("jahr", this.jahr);
      me.echtId = e.echtId;
      localStorage.setItem("echtId", this.echtId);
      me.refresh();
    },

    goBack() {
      this.$router.go(-1);
    },

    upload() {
      this.$refs.uploadSusaDlg.show(this.jahr, this.echtId);
    },

    uploadByItem(item) {
      this.$refs.uploadSusaDlg.show(this.jahr, this.echtId, item.monat);
    },

    deleteByItem(item) {
      this.$confirm(
        "Datei wirklich löschen?", {
        title: "Wirklich löschen?",
        icon: "mdi-comment-question-outline",
        color: '#e30613'
      }
      ).then(res => {
        if (res == true) {
          this.doDeleteByItem(item);
        }
      });
    },

    async doDeleteByItem(item) {
      const me = this;
      const params = {
        jahr: this.jahr,
        monat: item.monat,
        susaSystem: item.susaSystem,
        echtId: item.echtId
      };
      await me.api.delete("DeleteSusaFile",
        {
          params: params
        });
      me.refresh();
    },

    async download(item) {
      const sessionInfo = localStorage.getObject('sessionInfo');
      const apiCsv = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL,
        timeout: 100000000,
        responseType: 'blob',
        headers: {
          'x-functions-key': sessionInfo.FuncAppKey,
          common: {        // can be common or any other method
            accessToken: sessionInfo.KeyCloakParam.KeyCloakToken.access_token,
          }
        }
      }
      );
      const params = {
        jahr: this.jahr,
        monat: item.monat,
        echtId: item.echtId
      };

      const res = await apiCsv.get("DownloadSusaFile",
        {
          params: params
        });

      var fileName = res.headers['x-suggested-filename'];
      fileSaver.saveAs(res.data, fileName);
    },

    createHeaders() {
      //const me = this;
      const headers = [
        {
          text: "",
          value: "hasFile",
          width: 50,
        },
        {
          text: "Monat",
          align: "start",
          width: 180,
          sortable: true,
          value: "monatText",
        },
        {
          text: "Dateiname",
          align: "start",
          sortable: true,
          value: "fileName",
          width: 230,
        },
        {
          text: "",
          value: "upload",
          width: 20,
        },
        {
          text: "",
          value: "delete",
        },
      ];
      return headers;
    },
  },
};
</script>
