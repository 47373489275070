<template>
  <v-dialog
      v-model="show"
      width="500px"
      persistent
  >
    <v-card>
      <v-card-title>{{ apiName }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <p>{{ apiDescription }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p>Bitte geben Sie Ihren API-Client Token ein:</p>
            <v-text-field
                label="API-Key"
                required
                persistent-hint
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p>Bitte geben Sie Ihr API-Secret ein:</p>
            <v-text-field
                label="API-Secret"
                required
                persistent-hint
                type="password"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue-darken-1" flat @click.stop="show=false">Schließen</v-btn>
        <v-spacer></v-spacer>
          <v-btn
              color="#E95E27"
              variant="text"
              @click.stop="show=false"
          >
            Speichern
          </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  methods: {},
  props: {
    value: Boolean,
    apiName: String,
    apiDescription: String
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>