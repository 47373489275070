import Vue from "vue";
import VueRouter from "vue-router";
import ReportView from "../views/ReportView";
//import UploadGlobalView from "../views/UploadGlobalView";
//import UploadSusaView from "../views/UploadSusaView";
//import KontoMappingView from "../views/KontoMappingView";
import LoginView from "../views/LoginView";
import CallbackView from "../views/CallbackView";
import HomeView from "../views/HomeView";
//import APIManagementView from "../views/APIManagementView";
//import ItemMappingView from "../views/ItemMappingView";

Storage.prototype.setObject = function (key, value) {
  this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function (key) {
  var value = this.getItem(key);
  return value && JSON.parse(value);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "home",
    component: HomeView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/:reportName/:pageName",
    name: "report",
    component: ReportView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,

    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/callback",
    name: "callback",
    component: CallbackView,

    meta: {
      requiresAuth: false,
    },
  }
];

const router = new VueRouter({
  routes: routes,
  mode: "history",
});

let accessToken = null;
const sessionInfo = localStorage.getObject("sessionInfo");
if (sessionInfo) {
  accessToken = sessionInfo.KeyCloakParam.KeyCloakToken.access_token;
}

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth === true) {
    if (accessToken) {
      next();
    } else {
      const redirectTo = to.path.toString();
      localStorage.setItem("redirectTo", redirectTo);
      next({ name: "login" });
    }
  } else next();
});

export default router;
