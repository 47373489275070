<template>
  <reportContainer ref="myReportContainer" :style="getStyle" :SelectedGroups="SelectedGroups"></reportContainer>
</template>
<script>
import reportContainer from "../components/other/ReportContainer.vue";
export default {
  name: "reportView",

  components: {
    reportContainer,
  },

  computed: {
    getStyle() {
      if (this.isMobile) {
        //Workaround to expand space on right for scrollbar
        return "width: 104%; padding: 0px; margin: 0px;";
      }
      return "width: 100%; padding: 0px; margin: 0px;";
    },
  },
  props: {
    SelectedGroups: []
  },
  methods: {
    refresh() {
      this.$refs.myReportContainer.refresh();
    },
  },
};
</script>
