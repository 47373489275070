<template>
    <div>
        <v-data-table :headers="headers" :items="echtPartner" item-key="name" fixed-header :height="calculatedMaxHeight"
            dense :search="search">
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        <input type="checkbox" v-model="selected" :value="item" @change="handleClick(item)">
                    </td>
                    <td>{{ item.short_name }}</td>
                    <td>
                        <v-icon v-if="item.ACCOUNT_ID" color="primary" dark>mdi-check</v-icon>
                        <v-icon v-else color="error" dark>mdi-close</v-icon>
                    </td>
                    <td>{{ item.ECHT_PARTNER_ID }}</td>
                    <td>{{ item.Standort }}</td>
                    <td>
                        <div class="chip-container">
                            <v-chip v-if="item.name_1" outlined small class="ma-1 centered-text" color="primary" dark>
                                {{ item.name_1 }}
                            </v-chip>
                            <v-chip v-if="item.name_2" outlined small class="ma-1 centered-text" color="primary" dark>
                                {{ item.name_2 }}
                            </v-chip>
                            <v-chip v-if="item.name_3" outlined small class="ma-1 centered-text" color="primary" dark>
                                {{ item.name_3 }}
                            </v-chip>
                        </div>
                    </td>
                    <td>
                        <a>
                            {{ item.country }}, {{ item.zip_city }}, {{ item.street }}
                        </a>
                    </td>
                    <td>
                        <div class="chip-container">
                            <v-chip v-if="item.homepage" outlined small class="ma-1 centered-text" color="primary" dark>
                                {{ item.homepage }}
                            </v-chip>
                            <v-chip v-if="item.mail" outlined small class="ma-1 centered-text" color="primary" dark>
                                {{ item.mail }}
                            </v-chip>
                            <v-chip v-if="item.phone" outlined small class="ma-1 centered-text" color="primary" dark>
                                {{ item.phone }}
                            </v-chip>
                        </div>
                    </td>
                    <td>
                        <div class="chip-container">
                            <v-chip v-if="item.iban" outlined small class="ma-1 centered-text" color="primary" dark>
                                {{ item.iban }}
                            </v-chip>
                            <v-chip v-if="item.iln" outlined small class="ma-1 centered-text" color="primary" dark>
                                {{ item.iln }}
                            </v-chip>
                            <v-chip v-if="item.vat_id" outlined small class="ma-1 centered-text" color="primary" dark>
                                {{ item.vat_id }}
                            </v-chip>
                        </div>
                    </td>
                    <td>
                        <v-icon v-if="item.Dirty == 0" color="green" dark>mdi-flag</v-icon>
                        <v-icon v-else color="error" dark>mdi-flag</v-icon>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
const emitter = require('tiny-emitter/instance');
import axios from 'axios';

export default {
    data() {
        return {
            selected: [],
            echtPartner: [],
            headers: [
                { text: '', value: '' },
                { text: 'Kurzname', value: 'short_name' },
                { text: 'CRM', value: 'crm' },
                { text: 'EchtId', value: 'ECHT_PARTNER_ID' },
                { text: 'Standort', value: 'Standort' },
                { text: 'Namen', value: 'names' },
                { text: 'Anschrift', value: 'address' },
                { text: 'Kontakt', value: 'contact' },
                { text: 'Bank', value: 'bank' },
                { text: 'Bestätigt', value: 'actions' }
            ],
            api: axios.create({
                baseURL: process.env.VUE_APP_BASE_URL,
                timeout: 100000000
            }),
            loading: false
        }
    },
    computed: {
        calculatedMaxHeight() {
            return this.maxHeigth;
        },
    },
    methods: {
        async getPartner() {
            this.loading = true;
            await this.api.get('GetEchtPartnerSingleParents').then(response => {
                this.echtPartner = response.data;
                this.loading = false;
            });
        },
        handleClick() {
            this.$emit('selectChild', this.selected);
        },
    },
    mounted() {
        this.getPartner();
        emitter.on('partnerMerged', () => {
            this.selected = [];
            this.$emit('selectChild', this.selected);
            this.echtPartner = [];

            this.getPartner();
        });
    },
    props: {
        search: {
            type: String,
            default: ''
        },
        maxHeigth: {
            type: String,
            default: 'calc((100vh - 300px) / 2'
        }
    }
}
</script>

<style>
.selected {
    background-color: #eeeeee;
}
</style>