import {defineComponent} from "vue";
import ApiDialogAdmin from "@/components/dialogs/ApiDialogAdmin.vue";

export class APIItem {
    constructor(id, echtid, name, description,category, image, link = "", status = "inactive", nessassary = false) {
        this.id = id
        this.name = name
        this.description = description
        this.backgroundImage = image
        this.link = this.generateLink(link, echtid)
        this.status = status
        this.nessassary = nessassary
        this.category = category
    }

    generateLink(link, echtid) {
        if(link) {
            return link.replace('REPLACE_ECHTID', echtid)
        }
    }
}

export default defineComponent({
    components: {ApiDialogAdmin}
})
