<script src="../classes/APIItem.js"></script>

<template>
  <v-container fluid v-if="isLoggedIn == true">
    <h3>API-Management</h3>
    <!-- <v-text-field
        class="mt-8"
        label="Suchen"
        dense
        solo-inverted
        clearable
        v-model="searchText"
        clear-icon="mdi-close-circle-outline"
    ></v-text-field>-->

    <v-tabs>
      <v-tab v-for="group in groups" :key="group.name" @click="changeCategory(group.name)">
        {{ group.name }}
      </v-tab>
    </v-tabs>

    <v-data-table dense :headers="headers" :items="items" item-key="name" display-text="name" class="mt pt-0"
      :search="searchText" :items-per-page="-1">
      <template #[`item.status`]="{ item }">
        <v-icon color="#067800" v-if="item.status === 1 && item.nessassary === true">
          mdi-check
        </v-icon>
        <v-icon color="grey" v-if="item.status === 0 && item.nessassary === false">
          mdi-close
        </v-icon>
        <v-icon color="#000478" v-if="item.status === 2 && item.nessassary === true">
          mdi-check
        </v-icon>
        <v-icon color="gery" v-if="item.status === 0 && item.nessassary === true">
          mdi-crop-square
        </v-icon>
      </template>
      <template #[`item.link`]="{ item }">
        <v-btn small icon @click="openLinkInPopup(item.link)" v-if="item.link && item.nessassary && item.status !== 2">
          <v-icon color="grey">
            mdi-web
          </v-icon>
        </v-btn>
        <!-- TODO: Add more selfservice onboarding options
        <v-btn small icon v-if="!item.link && item.nessassary && item.status !== 2">
          <v-icon color="grey">
            mdi-key-plus
          </v-icon>
        </v-btn>-->
      </template>
    </v-data-table>
  </v-container>
</template>


<style>
html {
  overflow-y: auto;
}
</style>


<script>
import {APIItem} from "@/classes/APIItem";
import ApiDialog from "@/components/dialogs/ApiDialog.vue";
import axios from 'axios';

import apiMixin from '@/mixins/apiMixin.js';

export default {
  name: "api-management-view",

  mixins: [apiMixin],


  mounted() {

    this.generateItems();
    const sessionInfo = localStorage.getObject("sessionInfo");
    if (sessionInfo != null) {
      this.name = sessionInfo.KeyCloakParam.KeyCloakUserInfo.LoginName;
      this.$emit("updateCount");
    }
  },
  methods: {
    openLinkInPopup(link) {
      if (link) {
        window.open(link, 'popupWindow', 'width=800,height=600,scrollbars=yes');
      }
    },
    async generateItems() {
      try {
        let api = axios.create({
          baseURL: process.env.VUE_APP_BASE_URL,
          timeout: 100000000
        });

        const response = await api.get(`GetApiItems`, {
          params : {
            echtId: this.SelectedGroup
          }
        });
        const responseData = response.data;

        let newItems = [];
        let newGroups = [
          {
            name: "Alle"
          }
        ];

        responseData.forEach((item, i) => {
          newItems.push(new APIItem(
              i,
              this.SelectedGroup,
              item.name,
              item.description,
              item.category,
              item.image,
              item.link,
              item.status,
              item.nessassary
          ));

          let exists = newGroups.findIndex(group => group.name === item.category) !== -1;

          if (!exists){
            newGroups.push({
              name: item.category
            });
          }

        });
        this.items = newItems.sort((a, b) => b.nessassary - a.nessassary);
        this.groups = newGroups;
      } catch (error) {
        console.error("Fehler beim Abrufen der API-Elemente:", error);
      }
    },
    selectItem(item) {
      this.selectedItem = item;
      this.showApiDialog = true;
    },
    changeCategory(item) {
      if (item === "Alle") {
        this.searchText = "";
        return;
      }

      this.searchText = item;
      console.log(item);
    },
  },
  props: {
    isLoggedIn: Boolean,
    SelectedGroup: Number
  },
  watch: {
    SelectedGroup(newVal) {
      if (newVal) {
        this.generateItems();
      }
    }
  },
  data() {
    return {
      searchText: '',
      items: [],
      showApiDialog: false,
      selectedItem: null,
      headers: [
        {
          value: "status",
          width: 100,
        },
        {
          text: "name",
          align: "start",
          width: 200,
          sortable: true,
          value: "name",
        },
        {
          align: "start",
          width: 200,
          value: "link",
        },
        {
          text: "category",
          key: "category",
          value: "category",
          align: "start",
        }
      ],
      groups: []
    };
  },
  components: {
    ApiDialog
  }
}


</script>
