<template>
    <v-container fluid>
      <v-text-field class="mb-0 mt-2"
        label="Suchen"
        dense
        solo-inverted
        clearable
        v-model="searchText"
        clear-icon="mdi-close-circle-outline"
      ></v-text-field>

      <v-data-table class="mt-0 pt-0" :height="height"
          dense
          fixed-header
          :search="searchText"
          :items-per-page="itemsPerPage"
          :headers="headers"
          :items="items"
          item-key="susaKontoNr"
          show-select
          v-model="selectedItems"
          @toggle-select-all="onToggleSelectAll($event)"
          @item-selected="onItemSelected($event)"
      >
        <template slot="no-data" v-if="loading == true">
          <p class="mt-12">Lade Daten...</p>
          <v-overlay opacity="0">
            <v-progress-circular
              indeterminate
              color="red"
              size="64"
            ></v-progress-circular>
          </v-overlay>                    
        </template>
        <template slot="no-data" v-if="loading == false">Keine Datensätze gefunden.</template>
        <template v-slot:[`item.isSelected`]='{ item }'>
          <v-checkbox style="height:24px;padding-top:0px" dense v-model="item.isSelected"></v-checkbox>
        </template>
        <template v-slot:[`item.bwaId`]='{ item }'>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">            
              <v-btn  v-bind="attrs" v-on="on" rounded small dense v-model="item.bwaId" @click="onAssignBwaClicked(item, '0')"><v-icon>mdi-alpha-b</v-icon></v-btn>
            </template>
            <span>Bilanzkonto zuweisen...</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">            
              <v-btn v-bind="attrs" v-on="on" rounded small dense v-model="item.bwaId" @click="onAssignBwaClicked(item, '1')"><v-icon>mdi-alpha-g</v-icon></v-btn>
            </template>
            <span>GuV-Konto zuweisen...</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">            
              <v-btn v-bind="attrs" v-on="on" rounded small dense v-model="item.bwaId" @click="onAssignToUnrelevant(item)"><v-icon small>mdi-square-off-outline</v-icon></v-btn>
            </template>
            <span>Zu -Nicht relevant- aussortieren</span>
          </v-tooltip>
        </template>
      </v-data-table>
  </v-container>
</template>
<style scoped>  
</style>
<script>

export default {
  name: "unmappedSusaTable",
  created() {
    this.headers = this.createHeaders();
  },
  mounted() {
  },
  data: () => ({
    headers: [],
    selectedItems: [],
    itemsPerPage:10,
    searchText: ''
  }),
  props: {
    items: Array,
    loading: Boolean,
    height: Number
  },
  methods: {


    onItemSelected() {
      setTimeout(() => {
        this.$emit("itemSelectionChanged", this.selectedItems);        
      }, 50);
    },

    onToggleSelectAll() {
      setTimeout(() => {
        this.$emit("itemSelectionChanged", this.selectedItems);        
      }, 50);
    },

    onAssignBwaClicked(item, filterRootBwaId) {
      this.$emit("assignBwa", {
        item: item, 
        filterRootBwaId : filterRootBwaId
      });
    },

    onAssignToUnrelevant(item) {
      this.$emit("assignToUnrelevant", {
        item: item
      });
    },

    createHeaders() {
      const headers = [
        {
          text: "Susa Konto-Nr",
          value: "susaKontoNr",
          width: 130
        },
        {
          text: "Susa Konto",
          value: "susaKontoBezeichnung",
          width: 400
        },
        {
          text: "Aktionen",
          value: "bwaId",
        },
      ];
      return headers;
    },   

  } 

};
</script>

