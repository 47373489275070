export default {
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    navMobileBreakPoint() {
      if (this.isMobile) return 10000;
      return 800;
    },
    isPortrait() {
      return this.$vssHeight > this.$vssWidth;
    },
    appBarHeight() {
      var height = this.$normalAppBarHeight;
      // var bp = this.$vuetify.breakpoint.name;
      // if (bp == "xs" || bp == "sm") height = this.$smallAppBarHeight;
      if (this.isMobile) height = this.$smallAppBarHeight;
      return height;
    },
    logoVisible() {
      var visible = true;
      var bp = this.$vuetify.breakpoint.name;
      if (bp == "xs" || bp == "sm") visible = false;
      return visible;
    },
    footerVisible() {
      var visible = true;
      var bp = this.$vuetify.breakpoint.name;
      if (bp == "xs" || bp == "sm") visible = false;
      return visible;
    },
    contentHeight() {
      let offset = 45;
      let subtractHeight = offset + this.appBarHeight;
      if (this.footerVisible) subtractHeight += 20;
      let height = parseInt(this.$vssHeight) - subtractHeight;
      return height;
    },
    reportContentHeight() {
      let offset = 0;
      let subtractHeight = offset + this.appBarHeight;
      if (this.footerVisible) subtractHeight += 20;
      let height = parseInt(this.$vssHeight) - subtractHeight;
      return height;
    },
  },
};
