<template>
  <v-container fluid id="files">
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      item-key="fileName"
      display-text="fileName"
      class="mt-8"
      :items-per-page="itemsPerPage"
      hide-default-footer active
    >
    <template #[`item.hasFile`]="{ item }">
      <v-icon color="green" v-if="item.hasFile==true">
        mdi-check-bold
      </v-icon>
    </template>
    <template #[`item.fileName`]="{ item }">
      <a target="_blank" v-if="item.hasFile==true" @click="download(item)">
        {{item.fileName}}
      </a>
    </template>
    <template #[`item.upload`]="{ item }">
      <v-btn small icon @click="uploadByItem(item)"><v-icon>mdi-file-upload</v-icon></v-btn>
    </template>
    <template #[`item.delete`]="{ item }">
      <v-btn small icon @click="deleteByItem(item)" v-if="item.hasFile==true"><v-icon>mdi-delete</v-icon></v-btn>
    </template>
    <template slot="no-data" v-if="loading == true">Lade Daten...</template>
    <template slot="no-data" v-if="loading == false">Keine Daten gefunden.</template>
    </v-data-table>
    <uploadGlobalDialog ref="uploadGlobalDlg" @fileUploaded="onFileUploaded($event)" :title="title"></uploadGlobalDialog>
    <v-overlay opacity="0" v-if="loading">
      <v-progress-circular
        indeterminate
        color="red"
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<style lang="scss">
#employees tr.v-data-table__selected {
  background: green !important;
}
</style>
<script>
import uploadGlobalDialog from '@/components/dialogs/UploadGlobalDialog.vue'
import fileSaver from 'file-saver';
import axios from 'axios';
import apiMixin from '@/mixins/apiMixin.js';

export default {
  name: "upload-konten-view",

  mixins: [apiMixin],

  props: {
  },

  components: {
    uploadGlobalDialog
  },

  data: () => ({
    loading: false,
    itemsPerPage: -1,
    items: [],
    headers: [],
    title: "Upload Table-Mappings-CSV Datei",
    type: "TableMappings",
  }),

  async created() {
    const me = this;
    me.loading = false;

    me.headers = me.createHeaders();
    me.refresh();
  },

  methods: {

    async refresh() {
      const me = this;
      try {
        me.loading = true;
        const res = await me.api.get("GetGlobalFiles",
          {
            params: {
              type: me.type
            }
          });
        me.items = res.data;
      } finally {
        me.loading = false;
      }
    },

    onFileUploaded() {
      this.refresh();
    },

    uploadByItem(item) {
      this.echtId = item.echtId;
      this.$refs.uploadGlobalDlg.show(this.echtId, this.type);
    },

    async download(item) {
      const sessionInfo = localStorage.getObject('sessionInfo');
      const apiCsv = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL,
        timeout: 100000000,
        responseType: 'blob',
        headers: {
          'x-functions-key': sessionInfo.FuncAppKey,
          common: {        // can be common or any other method
            accessToken: sessionInfo.KeyCloakParam.KeyCloakToken.access_token,
          }
        }
      });
      const res = await apiCsv.get("DownloadGlobalFile",
        {
          params: {
            type: this.type,
            echtId: item.echtId,
          }
        });

      //Unfortunately for some reason the content-disposition header is not delivered in the res object
      //Therefore the server generated filename cannot be extracted from the content-disposition
      const fileName = `${item.echtId}-${this.type}.csv`
      fileSaver.saveAs(res.data, fileName);

    },

    deleteByItem(item) {
      this.$confirm(
        "Datei wirklich löschen?", {
        title: "Wirklich löschen?",
        icon: "mdi-comment-question-outline",
        color: '#e30613'
      }
      ).then(res => {
        if (res == true) {
          this.doDeleteByItem(item);
        }
      });
    },

    async doDeleteByItem(item) {
      const me = this;
      const params = {
        echtId: item.echtId,
        type: this.type
      };
      await me.api.delete("DeleteGlobalFile",
        {
          params: params
        });
      me.refresh();
    },

    goBack() {
      this.$router.go(-1);
    },

    upload() {
      this.$refs.uploadGlobalDlg.show(this.echtId, this.type);
    },

    createHeaders() {
      const headers = [
        {
          text: "",
          value: "hasFile",
          width: 50,
        },
        {
          text: "Gastrobetrieb",
          align: "start",
          width: 400,
          sortable: true,
          value: "gastrobetriebName",
        },
        {
          text: "Dateiname",
          align: "start",
          width: 200,
          sortable: true,
          value: "fileName",
        },
        {
          text: "",
          value: "upload",
          width: 30,
        },
        {
          text: "",
          value: "delete",
        },
      ];
      return headers;
    },
  },
};
</script>