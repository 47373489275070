<template>
    <v-dialog v-model="mappedShow" max-width="400" persistent>
        <v-card>
            <v-card-title class="headline">Mitarbeiter zuordnen</v-card-title>
            <v-card-text>
                <h4>Wollen Sie wirklich</h4> 
                <h3>{{ this.target_employee.other_name }}</h3> 
                <h4>bei</h4>
                <h3>{{ this.sourceEmployee.master_name }}</h3>
                <h4>hinzufügen?</h4>
            </v-card-text>
            <v-card-actions>
                <v-btn color="green darken-1" @click="acceptDialog">Bestätigen</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" @click="closeDialog">Abbrechen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            mappedShow: false,
            sourceEmployee: '',
            target_employee: ''
        }
    },
    methods: {
        /**
         * Close dialog
         */
        closeDialog() {
            this.mappedShow = false;
        },

        /**
         * Accept dialog
         */
        acceptDialog() {
            this.mappedShow = false;
            this.$emit('update_mapped', true);
        },

        /**
         * Open dialog for check mapping employee
         * @param sourceEmployee 
         * @param target_employee 
         */
        openMappedDialog(sourceEmployee, target_employee) {
            this.sourceEmployee = sourceEmployee;
            this.target_employee = target_employee;
            this.mappedShow = true;
        }
        
    }
}

</script>