<template>
    <div>
        <v-data-table :headers="headers" :items="echtPartner" item-key="name" fixed-header
            height="calc((100vh - 200px) / 2" hide-default-footer disable-pagination dense :loading="loading">
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        <input type="checkbox" v-model="selected" :value="item" @change="handleClick()"
                            :hidden="selectable ? false : true" :disabled="item.Dirty == 0 ? true : false">
                    </td>
                    <td>
                        <v-icon v-if="item.ECHT_PARTNER_ID == item.ECHT_PARTNER_ID_PARENT" color="orange"
                            dark>mdi-crown</v-icon>
                    </td>
                    <td>{{ item.short_name }}</td>
                    <td>
                        <v-icon v-if="item.ACCOUNT_ID" color="primary" dark @click="redirectToCRM(item.ACCOUNT_ID)">
                            mdi-microsoft-dynamics-365</v-icon>
                        <v-icon v-else color="grey" dark>mdi-microsoft-dynamics-365</v-icon>
                    </td>
                    <td>{{ item.ECHT_PARTNER_ID }}</td>
                    <td>{{ item.Standort }}</td>
                    <td>
                        <div class="chip-container">
                            <v-chip v-if="item.name_1" outlined small class="ma-1 centered-text" color="primary" dark>
                                {{ item.name_1 }}
                            </v-chip>
                            <v-chip v-if="item.name_2" outlined small class="ma-1 centered-text" color="primary" dark>
                                {{ item.name_2 }}
                            </v-chip>
                            <v-chip v-if="item.name_3" outlined small class="ma-1 centered-text" color="primary" dark>
                                {{ item.name_3 }}
                            </v-chip>
                        </div>
                    </td>
                    <td>
                        <a> {{ item.country }}, {{ item.zip_city }}, {{ item.street }} </a>
                    </td>
                    <td>
                        <div class="chip-container">
                            <v-chip v-if="item.homepage" outlined small class="ma-1 centered-text" color="primary" dark>
                                {{ item.homepage }}
                            </v-chip>
                            <v-chip v-if="item.mail" outlined small class="ma-1 centered-text" color="primary" dark>
                                {{ item.mail }}
                            </v-chip>
                            <v-chip v-if="item.phone" outlined small class="ma-1 centered-text" color="primary" dark>
                                {{ item.phone }}
                            </v-chip>
                        </div>
                    </td>
                    <td>
                        <div class="chip-container">
                            <v-chip v-if="item.iban" outlined small class="ma-1 centered-text" color="primary" dark>
                                {{ item.iban }}
                            </v-chip>
                            <v-chip v-if="item.iln" outlined small class="ma-1 centered-text" color="primary" dark>
                                {{ item.iln }}
                            </v-chip>
                            <v-chip v-if="item.vat_id" outlined small class="ma-1 centered-text" color="primary" dark>
                                {{ item.vat_id }}
                            </v-chip>
                        </div>
                    </td>
                    <td>
                        <v-icon v-if="item.Dirty == 0" color="green" dark>mdi-flag</v-icon>
                        <v-icon v-else color="error" dark>mdi-flag</v-icon>
                    </td>
                    <td>
                        <SplitPartnerDialog :item="item" @splitPartner="handleSplitPartner"
                            :hidden="splitable ? false : true" :parentPartnerId="parentPartnerId"></SplitPartnerDialog>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import axios from "axios";
import SplitPartnerDialog from "../../../dialogs/partnerApp/SplitPartnerDialog.vue";
const emitter = require("tiny-emitter/instance");

export default {
    data() {
        return {
            selected: [],
            search: "",
            parentPartnerId: null,
            echtPartner: [],
            headers: [
                { text: "", value: "" },
                { text: "", value: "mainPartner" },
                { text: "Kurzname", value: "name" },
                { text: "CRM", value: "crm" },
                //{ text: 'SAP Nr.', value: 'sap-nr' },
                { text: "EchtId", value: "echtPartnerId" },
                { text: "Standort", value: "location" },
                { text: "Namen", value: "names" },
                { text: "Anschrift", value: "address" },
                { text: "Kontakt", value: "contact" },
                { text: "Bank", value: "bank" },
                { text: "Bestätigt", value: "actions" },
                { text: "", value: "actions", sortable: false },
            ],
            api: axios.create({
                baseURL: process.env.VUE_APP_BASE_URL,
                timeout: 100000000,
            }),
            loading: false,
        };
    },
    components: {
        SplitPartnerDialog,
    },
    computed: {},
    methods: {
        handleSplitPartner() {
            this.refreshChilds();
            emitter.emit("refreshParent");
        },
        async refreshChilds() {
            console.log("refreshing childs");
            this.loading = true;
            await this.api
                .get("/GetEchtPartnerChilds?parentId=" + this.parentPartnerId)
                .then((response) => {
                    this.selected = [];
                    this.echtPartner = response.data;
                    this.loading = false;
                });
        },
        handleClick() {
            this.$emit("confirmedChilds", this.selected);
        },
        redirectToCRM(ACCOUNT_ID) {
            //TODO: put in env file
            //window.location.href = `https://echt.crm4.dynamics.com/main.aspx?appid=81ce95b5-b5cd-4c71-9783-bf65f4a8380c&forceUCI=1&pagetype=entityrecord&etn=account&id=${ACCOUNT_ID}`;
            //open in new tab
            window.open(
                `https://echt.crm4.dynamics.com/main.aspx?appid=81ce95b5-b5cd-4c71-9783-bf65f4a8380c&forceUCI=1&pagetype=entityrecord&etn=account&id=${ACCOUNT_ID}`,
                "_blank"
            );
        },
    },
    mounted() {
        emitter.on("echtPartnerId", async (echtPartnerId) => {
            this.echtPartner = [];
            this.loading = true;
            this.parentPartnerId = echtPartnerId;
            await this.api
                .get("/GetEchtPartnerChilds?parentId=" + echtPartnerId)
                .then((response) => {
                    this.echtPartner = response.data;
                    this.loading = false;
                });
        });
    },
    props: {
        selectable: {
            type: Boolean,
            default: true,
            required: true,
        },
        splitable: {
            type: Boolean,
            default: true,
            required: true,
        },
    },
};
</script>

<style>
.chip-container {
    display: flex;
    flex-direction: column;
}

.centered-text {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
