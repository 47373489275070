import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/es5/locale/de'
Vue.use(Vuetify);
export default new Vuetify({
    lang: {
        locales: {
            de,
        },
        current: 'de',
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#213239',
                secondary: '#9a7348',
                accent: '#8c9eff',
                error: '#e30613',
            },
        }
    },
})