<template>
  <v-container>
  </v-container>
</template>
<script>
import apiMixin from '@/mixins/apiMixin.js';
import axios from 'axios';

export default {
  name: "auth-callback",
  mixins: [apiMixin],

  mounted() {
    this.storeTokenAndRedirect();
  },

  methods: {
    async storeTokenAndRedirect() {
      const me = this;
      const authcode = me.$route.query.code;
      console.log(authcode)
      if (!authcode) return;

      let res;
      try {
        //This first api call will not use the mixin api (this.api) 
        //because sessionInfo is not set, yet
        let api = axios.create({
          baseURL: process.env.VUE_APP_BASE_URL,
          timeout: 100000000
        });
        res = await api.get(`PBiAppAuthFlow?authcode=${authcode}&init=true`);
      }
      catch (error) {
        this.$root.$children[0].isLoggedIn = true // stop login animation.
        this.$alert({
          title: "Fehler beim Laden",
          text: "Beim Laden ist ein Fehler aufgetreten: " + error + (error.response != undefined && error.response.data != undefined ? "\nDetails: " + error.response.data : ""),
          dialogMaxWidth: 400,
          acceptText: "Schliessen",
        });
        localStorage.setObject("sessionInfo", null);
        return;
      }
      if (res && res.status === 200) {
        localStorage.setObject("sessionInfo", res.data);
        let redirectTo = localStorage.getItem("redirectTo");
        if (redirectTo) {
          localStorage.setItem("redirectTo", "");
        }
        else {
          redirectTo = "";
        }
        setTimeout(() => {
          this.$router.push({ path: redirectTo }).catch(() => {});
        }, 200);
      }
    }
  }



};
</script>