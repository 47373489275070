<template>
    <v-alert dismissible :type="alertType" v-model="alert">
        {{ alertMessage }}
    </v-alert>
</template>

<script>
export default {
    data() {
        return {
            alert: false,
        };
    },
    props: {
        alertType: {
            Validator: value => ['error', 'success', 'info'].includes(value),
            type: String,
            required: true,
            default: 'info'
        },
        alertMessage: {
            type: String,
            required: true,
            default: ''
        }
    },
    methods: {
        newAlert() {
            this.alert = true;
            setTimeout(() => {
                this.alert = false;
            }, 3000);
        }
    },
    computed: {

    }
};
</script>

<style>
.v-alert {
    position: fixed;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    z-index: 9999;
}
</style>