<template>
    <v-dialog v-model="deleteShow" max-width="400" persistent>
        <v-card>
            <v-card-title class="headline">Mitarbeiter löschen</v-card-title>
            <v-card-text>
                <h4>Wollen Sie wirklich</h4> 
                <h3>{{ this.masterEmployeeToDelete.master_first_name + ' ' + this.masterEmployeeToDelete.master_last_name}}</h3>
                <h4>löschen?</h4>
            </v-card-text>
            <v-card-actions>
                <v-btn color="green darken-1" @click="acceptDialog">Bestätigen</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" @click="closeDialog">Abbrechen</v-btn>
            </v-card-actions>
        </v-card>
        <PopupAlert :alertType="alertType" :alertMessage="alertMessage" ref="alert">
        </PopupAlert>
    </v-dialog>
</template>

<script>


import apiMixin from '@/mixins/apiMixin.js';
import PopupAlert from "@/components/popupAlert.vue";

export default {
    data() {
        return {
            addShow: false,
            alertMessage: '',
            alertType: 'error',
            deleteShow: false,
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Aktion', value: 'action', sortable: false }
            ]
        }
    },

    mixins: [apiMixin],

    components: {
        PopupAlert,
    },

    props: {
        masterEmployeeToDelete:
        {
            type: [Object, String],
            default: () => ''
        },
        echtId:
        {
            type: Number,
            default: null
        }
    },

    methods: {
        /**
         * Close dialog
         */
        closeDialog() {
            this.deleteShow = false;
        },

        /**
         * Accept dialog
         */
        acceptDialog() {
            try{
                this.deleteCreatedEmployees();
                this.$emit('deletedEmployee', this.masterEmployeeToDelete);
            }
            catch (error) {
                this.$refs.alert.openAlert('Fehler beim Löschen der erstellten Mitarbeiter', 'error');
            }
            this.deleteShow = false;
        },

        /**
         * Open dialog for check delete employee
         */
        openDeleteEmployeeDialog() {
            this.deleteShow = true;
        },


        /**
         * Delete the created employees
         */
        deleteCreatedEmployees() {
            try {
                this.api.get('DeleteCreatedMasterEmployee?master_employee_id=' + this.masterEmployeeToDelete.master_employee_id);
            }
            catch (error) {
                this.newAlert('Fehler beim Löschen der erstellten Mitarbeiter', 'error');
            }
        },

        /**
         * Create a alert pop up message
         * @param message 
         * @param type 
         */
        newAlert(message, type) {
            this.alertMessage = message;
            this.alertType = type;
            this.$refs.alert.newAlert();
        },
    }
}
</script>