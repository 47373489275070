<template>
  <v-container fluid>
    <v-row>
      <v-dialog v-model="dialog" scrollable max-width="520">
        <v-form ref="form" v-model="valid">
          <v-card>
            <v-card-title class="pa-0">
              <v-toolbar dark>
                <v-toolbar-title>Upload SuSa-CSV-Datei</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="cancel()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 320px">
              <v-row class="mt-4">
                <v-col cols="4">
                  <v-autocomplete
                    dense
                    label="Jahr"
                    v-model="jahr"
                    :items="jahre"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="8">
                  <v-autocomplete
                    class="ml-4"
                    clear-icon="mdi-close-circle-outline"
                    dense
                    label="Monat"
                    v-model="monat"
                    :items="monate"
                    :rules="requiredRules"
                    required
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-autocomplete
                label="Gastrobetrieb"
                :items="partners"
                v-model="echtId"
                item-text="HandelsPartner"
                item-value="EchtId"
              ></v-autocomplete>
              <v-file-input
                ref="fileInput"
                :rules="fileUploadRules"
                class="mt-4"
                accept="text/csv, text/plain"
                label="SuSa CSV-Datei"
                required
                :value="file"
                @change="onFileChanged($event)"
              ></v-file-input>
              <v-alert
                v-if="errorText != ''"
                color="pa-2 ma-0 red lighten-2"
                dark
                dense
                style="white-space:pre-line"
              >
                {{ errorText }}
              </v-alert>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="cancel"> Abbrechen </v-btn>
              <v-btn color="primary" @click="upload"> Hochladen </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
        <v-overlay opacity="0" v-if="loading">
          <v-progress-circular
            indeterminate
            color="red"
            size="64"
          ></v-progress-circular>
        </v-overlay>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import refData from "../../staticData/referenceData.js";
import apiMixin from "@/mixins/apiMixin.js";

export default {
  name: "uploadSusaDialog",

  mixins: [apiMixin],

  created() {
    const sessionInfo = localStorage.getObject("sessionInfo");
    this.partners = sessionInfo.KeyCloakParam.KeyCloakPartners;
    this.monate = refData.getMonate();
    this.jahre = refData.getJahre();
    this.requiredRules.push((v) => !!v || "Eingabe erforderlich.");
    this.fileUploadRules = [
      (v) =>
        !v ||
        v.size < 20000000 ||
        "Die Datei sollte nicht grösser als 20 MB sein.",
      (v) => !!v || "Eingabe erforderlich.",
    ];
  },
  data: () => ({
    dialog: false,
    loading: false,
    monate: [],
    jahre: [],
    monat: null,
    jahr: null,
    echtId: null,
    requiredRules: [],
    fileUploadRules: [],
    file: null,
    valid: false,
    errorText: "",
    partners: [],
  }),
  methods: {
    cancel() {
      this.dialog = false;
    },

    show(jahr, echtId, monat) {
      this.valid = true;
      this.errorText = false;
      this.file = null;
      this.jahr = jahr;
      this.echtId = echtId;
      if (monat) this.monat = monat;
      this.dialog = true;
    },

    onFileChanged(e) {
      this.file = e;
    },

    async upload() {
      const me = this;
      if (!this.$refs.form.validate()) return;
      const params = new URLSearchParams({
        jahr: me.jahr,
        monat: me.monat,
        echtId: me.echtId,
        fileName: me.file.name
      }).toString();

      let res;
      try {
        res = await me.api.get(`ExistsSusaFile?${params}`);
        if (res.data == true) {
          me.$confirm("Diese Datei existiert schon. Überschreiben?", {
            title: "Datei überschreiben?",
            icon: "mdi-comment-question-outline",
            color: "#e30613",
          }).then((res) => {
            if (res == true) {
              this.doUpload(params);
            }
          });
        } else {
          this.doUpload(params);
        }
      } catch (err) {
        console.log(err);
        if (err.response && err.response.status == 400) {
          this.loading = false;
          this.errorText = err.response.data;
          return;
        }
      }
    },

    async doUpload(params) {
      try {
        this.loading = true;
        await this.api.post(`UploadSusaFile?${params}`, this.file);
        this.loading = false;
      } catch (err) {
        if (err.response) {
          if (err.response.status == 400) {
            this.loading = false;
            this.errorText = err.response.data;
            return;
          } else if (err.response.status == 409) {
            this.loading = false;
            this.errorText = "Diese Datei existiert bereits.";
            return;
          }
        }
      }
      this.valid = true;
      this.monat = null;
      this.file = null;
      this.$emit("fileUploaded", { jahr: this.jahr, echtId: this.echtId });
      this.dialog = false;
    },
  },
};
</script>

