<template>
    <v-container fluid height="calc((100vh - 0px) / 3">
        <v-row no-gutters>
            <v-col cols="12" sm="6" md="6">
                <v-row no-gutters style="height: 50px">
                    <v-col cols="12" sm="8" md="8">
                        <v-combobox :items="creditors" item-text="creditor_name" item-value="creditor_id"
                            label="Lieferant" prepend-icon="mdi-magnify" height="30px" @keyup="getCreditorByParentName"
                            @change="emitSearchCreditor" clearable>
                        </v-combobox>
                    </v-col>
                </v-row>
                <v-row no-gutters style="height: 50px">
                    <v-col cols="12" sm="8" md="8">
                        <v-combobox :items="itemsForSearch" item-text="item_description" item-value="item_description"
                            label="Artikelname" prepend-icon="mdi-magnify" @keyup="getItemsByDescriptionForSearch"
                            @input="emitSearchDescription" height="30px" clearable v-model="itemModel" :key="renderKey">
                        </v-combobox>
                    </v-col>
                </v-row>
                <v-row no-gutters style="height: 50px">
                    <v-col cols="12" sm="8" md="8">
                        <v-combobox :items="itemsForSearch" item-text="item_no" item-value="item_no"
                            label="Artikelnummer (SAP-Nummer)" prepend-icon="mdi-magnify" @keyup="getItemsByNoForSearch"
                            @change="emitSearchNo" height="30px" clearable>
                        </v-combobox>
                    </v-col>
                </v-row>
            </v-col>
            <!-- This should show the creditors and the number of unmapped items. Will be implemented in thenext iteration-->
            <!-- <v-col cols="12" sm="6" md="6">
                <v-data-table :headers="headers" fixed-header :items-per-page="5" class="elevation-1"
                    :items="this.creditors" dense>
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                {{ item.creditor_name }}
                            </td>
                            <td>
                                {{ item.number_of_unmapped_items }}
                            </td>
                        </tr>
                    </template>
</v-data-table>
</v-col> -->
        </v-row>
        <PopupAlert :alertType="alertType" :alertMessage="alertMessage" ref="alert">
        </PopupAlert>
    </v-container>
</template>

<script>

import apiMixin from '@/mixins/apiMixin.js';
import PopupAlert from "@/components/popupAlert.vue"

export default {
    data() {
        return {
            alertType: 'error',
            alertMessage: '',
            select: null,
            creditors: [],
            creditorParentName: '',
            renderKey: 0,
            itemModel: '',
            itemsForSearch: [],
            itemNoForSearch: '',
            itemDescriptionForSearch: '',
            // This should show the creditors and the number of unmapped items. Will be implemented in thenext iteration
            // headers: [
            //     { text: 'Lieferant', value: 'creditor_name', sortable: true, width: '50%' },
            //     { text: 'Anzahl nicht zugeorndete Artikel', value: 'number_of_unmapped_items', sortable: true, width: '50%' }
            // ],
        }
    },

    mixins: [apiMixin],

    methods: {
        /**
         * Emit the creditor id from the selected creditor of the combobox to the ArtikelApp View
         * @param value 
         */
        emitSearchCreditor(value) {
            if (value != null) {
                this.$emit('searchCreditor', value.creditor_id)
                return
            }
            this.$emit('searchCreditor', null)
        },
        /**
         * Emit the item name from the selected creditor of the combobox to the ArtikelApp View
         */
        emitSearchDescription(value) {
            this.renderKey = this.renderKey + 1
            // If search is empty
            if (value == null) {
                this.$emit('searchDescription', null)
                this.itemsForSearch = []
                return
            }
            // If search is user input
            if (value.item_description == null) {
                this.$emit('searchDescription', value)
                this.itemsForSearch = []
                return
            }
            // If search is selected from the combobox
            if (value != null) {
                this.$emit('searchDescription', value.item_description)
                this.itemsForSearch = []
                return
            }
        },
        /**
         * Emit the item id from the selected creditor of the combobox to the ArtikelApp View
         * @param value 
         */
        emitSearchNo(value) {
            let item_ids = []
            this.itemsForSearch.forEach(element => {
                if (element.item_no == value.item_no) {
                    item_ids.push(element.item_id)
                }
            });
            if (value != null) {
                this.$emit('searchIds', item_ids)
                this.itemsForSearch = []
                return
            }
            this.$emit('searchIds', null)
            this.itemsForSearch = []
        },
        /**
         * Get the parent name of the creditor if user input characters into the combobox
         * @param value 
         */
        async getCreditorByParentName(value) {
            this.creditors = []
            this.creditorParentName = value.srcElement._value
            try {
                await this.artikelAppApi.get('getCreditorByParentName?creditor_parent_name=' + this.creditorParentName).then(response => {
                    response.data.forEach(creditor => {
                        this.creditors.push(creditor)
                    });
                })
            } catch (error) {
                console.log(error)
                this.newAlert(`Fehler beim Suchen von Artikel über den Lieferantennamen `, 'error');
            }
        },
        /**
         * Get the item based of the description if user input characters into the combobox
         * @param value 
         */
        async getItemsByDescriptionForSearch(value) {
            this.itemDescriptionForSearch = value.srcElement._value
            if (this.itemDescriptionForSearch != '') {
                try {
                    await this.artikelAppApi.get('getItemsForDescriptionSearch?item_description=' + this.itemDescriptionForSearch).then(response => {
                        this.itemsForSearch = response.data
                    })
                } catch (error) {
                    console.log(error)
                    this.newAlert(`Fehler beim Suchen von Artikel über den Artikelnamen `, 'error');
                }
            }
        },
        /**
         * Get the item based of the id if user input characters into the combobox
         * @param value 
         */
        async getItemsByNoForSearch(value) {
            this.itemNoForSearch = value.srcElement._value
            try {
                await this.artikelAppApi.get('getItemsForNoSearch?item_no=' + this.itemNoForSearch).then(response => {
                    this.itemsForSearch = response.data
                })
            } catch (error) {
                console.log(error)
                this.newAlert(`Fehler beim Suchen von Artikel über die SAP-Nummer `, 'error');
            }
        },
        /**
         * Create a alert pop up message
         * @param message 
         * @param type 
         */
        newAlert(message, type) {
            this.alertMessage = message;
            this.alertType = type;
            this.$refs.alert.newAlert();
        },
    },
    props: {
        items: {
            type: Array,
            default: () => []
        },
    },
    components: {
        PopupAlert
    },
}
</script>
