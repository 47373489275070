<template>
    <v-container fluid class="full-height">
        <v-row>
            <v-col cols="12" sm="6" md="6">
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-text-field v-model="search" prepend-icon="mdi-magnify" label="Search" clearable
                            clear-icon="mdi-close-circle"></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="6">
                <div class="half-height">
                    <parentTable :search="search" maxheight="calc((100vh - 180px) / 2)" :editable="False"
                        ref="parentTable">
                    </parentTable>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="12">
                <div class="half-height">
                    <childTable @confirmedChilds="selectedToBeConfirmedChilds" ref="childTable" :selectable=false
                        :splitable=false>
                    </childTable>
                </div>
            </v-col>
        </v-row>
        <popupAlert :alertType="alertType" :alertMessage="alertMessage" ref="alert">
        </popupAlert>
    </v-container>
</template>

<script>
import axios from 'axios';

import parentTable from './tabels/parentTable.vue';
import childTable from './tabels/childTable.vue';

import popupAlert from '../../popupAlert.vue';

export default {
    data() {
        return {
            alertType: 'error',
            alertMessage: '',
            search: '',
            selected: [],
            api: axios.create({
                baseURL: process.env.VUE_APP_BASE_URL,
                timeout: 100000000
            }),
        };
    },
    components: {
        parentTable,
        childTable,
        popupAlert
    },
    methods: {
        selectedToBeConfirmedChilds(selected) {
            this.selected = selected;
        },
        async confirmPartner() {
            if (this.selected.some(item => item.Dirty == 0)) {
                const dirtyItem = this.selected.find(item => item.Dirty == 0);

                console.log(dirtyItem);

                this.newAlert(`${dirtyItem.short_name} ist bereits bestätigt`, 'error');
                return;
            }

            this.selected.forEach(async item => {
                try {
                    await this.api.patch('SetEchtPartnerDirty?partnerId=' + item.ECHT_PARTNER_ID).then(response => {
                        if (response.data != 1) {
                            this.newAlert(`Fehler beim Bestätigen von ${item.short_name}`, 'error');
                            return;
                        }
                        this.newAlert(`Bestätigung von ${item.short_name} erfolgreich`, 'success');
                    });
                } catch (error) {
                    console.error(error);
                    this.newAlert(`Fehler beim Bestätigen von ${item.short_name}`, 'error');
                }
            });
            await new Promise(r => setTimeout(r, 1000));

            this.$refs.childTable.refreshChilds();
            this.$refs.parentTable.refresh();
            this.selected = [];
        },
        newAlert(message, type) {
            this.alertMessage = message;
            this.alertType = type;
            this.$refs.alert.newAlert();
        }
    },
}
</script>

<style>
.full-height {
    height: calc(100vh - 160px);
}

.half-height {
    /*height: calc((100vh - 160px) / 2);*/
    max-height: calc((100vh - 180px) / 2);
    min-height: calc((100vh - 180px) / 2);
    overflow-y: auto;
}

.scrollable {
    overflow-y: scroll;
    padding: 5px;
}
</style>
