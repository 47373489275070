<template>
  <p></p>  
</template>
<script>

export default {
  name: "login-view",

  created() {
    const url = process.env.VUE_APP_LOGIN_URL;
    window.location.href = url;
  },  

};
</script>