<template>
    <v-container fluid v-if="isLoggedIn == true" class="full-height" width="100%" style="padding-bottom: 0px;">
        <v-row>
            <v-col cols="12" sm="12" md="12">
                <h3>
                    Artikel App
                </h3>
            </v-col>
        </v-row>
        <div style="padding: 0px;">
            <v-row>
                <v-col cols="12" sm="12" md="12" style="padding: 0px;">
                    <v-tabs v-model="tab">
                        <v-tab @click="changeTabs(0)">
                            Nicht zugeordnet
                        </v-tab>
                        <v-tab @click="changeTabs(1)">
                            Zugeordnet
                        </v-tab>
                    </v-tabs>
                </v-col>
            </v-row>
        </div>
        <v-row>
            <div style="width: 100%">
                <v-col cols="12" sm="12" md="12">
                    <FilterSearch :editable="true" @searchDescription="this.passSearchDescription"
                        @searchIds="this.passSearchIds" @searchCreditor="this.passSearchCreditor">
                    </FilterSearch>
                </v-col>
            </div>
        </v-row>
        <v-row>
            <div style="width: 100%">
                <v-col cols="12" sm="12" md="12">
                    <ItemInformation :gpc="gpc" :selectedTab="selectedTab" @save_items="this.setSaveItemsToArray"
                        v-if="gpcIsLoaded" @save_item="this.saveItem" :searchDescription="searchDescription"
                        :searchIds="searchIds" :searchCreditor="searchCreditor" :selectedItemsForDeleting="selectedItemsForDeleting"
                        @is_multiple_saving_enable="this.changeSaveButtonStatus">
                    </ItemInformation>
                </v-col>
            </div>
        </v-row>
        <v-row height="calc((100vh - 200px) / 10" style="width: 100%;" align-self="bottom" :key="renderKey">
            <v-col cols="12" sm="2" md="2">
                <v-combobox :items="gpc.level_1" item-text="level_1_title" item-value="level_1_code" label="GPC-Level-1"
                    clearable @input="updateGPCLevel($event, 1)" v-model="gpcModel" :disabled="tab == 1"></v-combobox>
            </v-col>
            <v-col cols="12" sm="2" md="2">
                <v-combobox :items="gpc.level_2" item-text="level_2_title" item-value="level_2_code" label="GPC-Level-2"
                    clearable @input="updateGPCLevel($event, 2)" v-model="gpcModel"
                    @click="restrictChoosableGPCLevelWhenLevelIsChoosen(2)" :disabled="tab == 1"></v-combobox>
            </v-col>
            <v-col cols="12" sm="2" md="2">
                <v-combobox :items="gpc.level_3" item-text="level_3_title" item-value="level_3_code" label="GPC-Level-3"
                    clearable @input="updateGPCLevel($event, 3)" v-model="gpcModel"
                    @click="restrictChoosableGPCLevelWhenLevelIsChoosen(3)" :disabled="tab == 1"></v-combobox>
            </v-col>
            <v-col cols="12" sm="2" md="2">
                <v-combobox :items="gpc.level_4" item-text="level_4_title" item-value="level_4_code" label="GPC-Level-4"
                    clearable @input="updateGPCLevel($event, 4)" v-model="gpcModel"
                    @click="restrictChoosableGPCLevelWhenLevelIsChoosen(4)" :disabled="tab == 1"></v-combobox>
            </v-col>
            <v-col cols="12" sm="4" md="4">
                <v-btn class="ma-2" color="primary" @click="saveMultipleItems" :disabled="tab == 1 || isMultipleSavingEnable == false">
                    Massenspeichern
                    <v-icon icon="mdi-checkbox-marked-circle" end></v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <PopupAlert :alertType="alertType" :alertMessage="alertMessage" ref="alert">
        </PopupAlert>
    </v-container>
</template>


<style>
.full-height {
    height: calc(100vh - 80px);
}
</style>

<script>

import FilterSearch from "@/components/artikelApp/filterSearch.vue";
import ItemInformation from "@/components/artikelApp/itemInformation.vue";
import apiMixin from '@/mixins/apiMixin.js';

import PopupAlert from "@/components/popupAlert.vue";

export default
    {
        data() {
            return {
                alertMessage: '',
                alertType: 'error',
                gpcIsLoaded: false,
                gpcModel: null,
                isMultipleSavingEnable: true,
                loading: false,
                selectedItems: [],
                selectedItemsForDeleting: [],
                searchDescription: '',
                searchCreditor: '',
                searchIds: [],
                selectedTab: false,
                tab: 0,
                tmpGpc: null,
                tmpGpcModel: null,
                tmpLevel2: [],
                tmpLevel3: [],
                renderKey: 0,
                gpc: {
                    level_1: [],
                    level_2: [],
                    level_3: [],
                    level_4: [],
                },
            }
        },
        name: "artikel-app-view",

        mixins: [apiMixin],

        props: {
            isLoggedIn: Boolean,
        },

        components: {
            FilterSearch,
            ItemInformation,
            PopupAlert
        },

        methods: {
            /**
             * After user pressed the button "Massenspeichern" the lowest gpc id will be 
             * determined and assign to each item before saving it to the database
             */
            async saveMultipleItems() {
                this.selectedItemsForDeleting = []
                let lowest_gpc_id = this.getLowestGCPIdForMultipleItems()
                this.selectedItems.forEach(selected_item => {
                    selected_item.lowest_gpc_id = lowest_gpc_id
                    this.saveItem(selected_item)
                })
                this.selectedItemsForDeleting = JSON.parse(JSON.stringify(this.selectedItems))
            },
            /**
             * Save item with lowest gpc id to database
             * @param item 
             */
            async saveItem(item) {
                try {
                    await this.artikelAppApi.get('updateOrInsertGPCLevel?item_id=' + item.item_id + '&gpc_level_1=' + item.gpc.level_1_code +
                        '&gpc_level_2=' + item.gpc.level_2_code + '&gpc_level_3=' + item.gpc.level_3_code + '&gpc_level_4=' + item.gpc.level_4_code +
                        '&lowest_gpc_id=' + item.lowest_gpc_id).then(
                            this.newAlert(`Ausgewählter Artikel ist jetzt bestätigt`, 'success')
                        );
                } catch (error) {
                    console.log(error)
                    this.newAlert(`Ausgewählter Artikel kann nicht bestätigt werden`, 'error')
                }
            },
            /**
             * Set selected items from the data table form the ItemInformation View to selectedItems array to save it
             * after user pressed the button "Massenspeichern"
             * @param item 
             */
            setSaveItemsToArray(item) {
                this.selectedItems = item
            },
            /**
             * Change tabs from "Zugeordnet" to "Nicht zugeordnet" and back
             * @param tab 
             */
            changeTabs(tab) {
                if (tab == 0) {
                    this.selectedTab = false
                }
                else {
                    this.selectedTab = true
                }
            },
            

            changeSaveButtonStatus(isMultipleSavingEnable){
                this.isMultipleSavingEnable = isMultipleSavingEnable
            },
            /**
             * Save item description from the combobox from the FilterSearch View to pass it to the ItemInformation View
             * @param searchDescription 
             */
            passSearchDescription(searchDescription) {
                this.searchDescription = searchDescription
            },
            /**
             * Save item id from the combobox from the FilterSearch View to pass it to the ItemInformation View
             * @param searchIds
             */
            passSearchIds(searchIds) {
                this.searchIds = searchIds
            },
            /**
             * Save creditor from the combobox from the FilterSearch View to pass it to the ItemInformation View
             * @param searchCreditor 
             */
            passSearchCreditor(searchCreditor) {
                this.searchCreditor = searchCreditor
            },
            /**
             * After the combobox for the gpc levels get an selected input of a specific gpc level
             * call the function to restrict the chice of the other combobocxes based on which combobox 
             * is slected and which gpc id is selected. Reset all restrictet gpc level, if combobx was cleared
             * @param value 
             */
            updateGPCLevel(value, level) {
                if (value != null) {
                    this.tmpGpcModel = value
                    this.tmpLevel2 = []
                    this.tmpLevel3 = []
                    this.restrictLowerGPCLevelChoice(value)
                    return
                }
                this.gpcModel = this.tmpGpcModel
                const keys = Object.keys(this.gpcModel)
                if (level == 1) {
                    for (const element of keys) {
                        this.gpcModel[element] = null
                    }
                }
                if (level == 2) {
                    for (let i = 2; i < keys.length; i++) {
                        this.gpcModel[keys[i]] = null
                    }
                }
                if (level == 3) {
                    for (let i = 4; i < keys.length; i++) {
                        this.gpcModel[keys[i]] = null
                    }
                }
                if (level == 4) {
                    for (let i = 6; i < keys.length; i++) {
                        this.gpcModel[keys[i]] = null
                    }
                }
                this.gpc = JSON.parse(JSON.stringify(this.tmpGpc))
                this.restrictLowerGPCLevelChoice()
                this.renderKey += 1
            },
            /**
             * When in the combobox a gpc level is choosen, restrict the choice of the lower comboboxes
             * @param level 
             */
            restrictChoosableGPCLevelWhenLevelIsChoosen(level) {
                if (this.gpcModel == null) { return }
                if (level == 2) {
                    this.restrictLevel2()
                    this.restrictLevel3()
                    this.restrictLevel4()
                    return
                }
                if (level == 3) {
                    this.restrictLevel3()
                    this.restrictLevel4()
                    return
                }
                if (level == 4) {
                    this.restrictLevel4()
                }
            },
            /**
             * If a gpc level is choosen in the v-combobox, the gpc levels under the choosen level
             * should only contain all sub levels of the choosen level
             */
            restrictLowerGPCLevelChoice() {
                // Nothing choosen
                if (this.gpcModel.level_1_code == null) { return }
                // Level 1 choosen
                if (this.gpcModel.level_2_code == null) {
                    this.restrictLevel2()
                    this.restrictLevel3()
                    this.restrictLevel4()
                    return
                }
                // Level 2 choosen
                if (this.gpcModel.level_3_code == null) {
                    this.restrictLevel3()
                    this.restrictLevel4()
                    return
                }
                // Level 3 choosen
                if (this.gpcModel.level_4_code == null) {
                    this.restrictLevel4()
                }
            },
            /**
             * Iterate over each gpc object of the gpc level 2 and check if the level 1 code of these 
             * level 2 gpc object is the same as the level 1 code of the choosen level 1 of the 
             * combobox
             */
            restrictLevel2() {
                this.gpc.level_2.forEach(level_2 => {
                    if (level_2.level_1_code == this.gpcModel.level_1_code) {
                        this.tmpLevel2.push(level_2)
                    }
                })
                this.gpc.level_2 = this.tmpLevel2
            },
            /**
             * Iterate over each gpc object of the gpc level 3 and check if the level 2 code of these 
             * level 3 gpc object is the same as the level 1 code of the choosen level 2 of the 
             * combobox
             */
            restrictLevel3() {
                this.gpc.level_3.forEach(level_3 => {
                    if (this.tmpLevel2.length == 0) {
                        if (level_3.level_2_code == this.gpcModel.level_2_code) {
                            this.tmpLevel3.push(level_3)
                        }
                    }
                    else {
                        this.tmpLevel2.forEach(level_2 => {
                            if (level_3.level_2_code == level_2.level_2_code) {
                                this.tmpLevel3.push(level_3)
                            }
                        })
                    }
                })
                this.gpc.level_3 = this.tmpLevel3
            },
            /**
             * Iterate over each gpc object of the gpc level 4 and check if the level 3 code of these 
             * level 4 gpc object is the same as the level 3 code of the choosen level 3 of the 
             * combobox
             */
            restrictLevel4() {
                let tmp_level_4 = []
                this.gpc.level_4.forEach(level_4 => {
                    if (this.tmpLevel3.length == 0) {
                        if (level_4.level_3_code == this.gpcModel.level_3_code) {
                            tmp_level_4.push(level_4)
                        }
                    }
                    else {
                        this.tmpLevel3.forEach(level_3 => {
                            if (level_4.level_3_code == level_3.level_3_code) {
                                tmp_level_4.push(level_4)
                            }
                        })
                    }
                });
                this.gpc.level_4 = tmp_level_4
            },
            /**
             * Add lowest gpc id after user has pressed the button "Massenspeichern"
             */
            getLowestGCPIdForMultipleItems() {
                if (this.gpcModel == null) { this.newAlert(`Bitte ein GPC-Level auswählen`, 'info'); return }
                if (this.gpcModel.level_4_code != null) { return this.gpcModel.level_4_code }
                if (this.gpcModel.level_3_code != null) { return this.gpcModel.level_3_code }
                if (this.gpcModel.level_2_code != null) { return this.gpcModel.level_2_code }
                if (this.gpcModel.level_1_code != null) { return this.gpcModel.level_1_code }
            },
            /**
             * Create an object where are gpc level 1 to gpc level 4 are saved. For each gpc level 
             * is a object with name level_n inside the gpc object with hold all corresponding ids for this level
             */
            async getGPCLevels() {
                try {
                    this.loading = true
                    await this.artikelAppApi.get('getGPCLevelAndName').then(response => {
                        let gpc_levels = response
                        gpc_levels.data.filter((level) => {
                            if (level.level_2_code == null) {
                                this.gpc.level_1.push(level)
                                return
                            }
                            if (level.level_3_code == null) {
                                this.gpc.level_2.push(level)
                                return
                            }
                            if (level.level_4_code == null) {
                                this.gpc.level_3.push(level)
                                return
                            }
                            this.gpc.level_4.push(level)
                        })
                    })
                    this.tmpGpc = JSON.parse(JSON.stringify(this.gpc))
                    this.loading = false
                    this.gpcIsLoaded = true
                } catch (error) {
                    console.log(error)
                    let sessionInfo = localStorage.getObject("sessionInfo")
                    if (sessionInfo.FuncArtikelAppKey == null) {
                        this.newAlert(`Fehler beim Laden da kein Authentifizierungs Key vorhanden ist. `, 'error');
                    }
                    else {
                        this.newAlert(`Fehler beim Laden der GPC Level und Namen `, 'error');
                    }
                }
            },
            /**
             * Check if the user is still logged in. Get the FuncArtikelAppKey 
             * from the response to authenticate the user
             */
            async authFlowArtikelApp() {
                try {
                    await this.artikelAppApi.get("artikelAppAuthFlow").then(
                        (data) => {
                            if (data && data.status === 200) {
                                let sessionInfo = localStorage.getObject("sessionInfo")
                                sessionInfo.FuncArtikelAppKey = (data.data.FuncArtikelAppKey)
                                localStorage.setObject("sessionInfo", sessionInfo)
                            } else {
                                this.logout();
                            }
                        },
                        (error) => {
                            console.log("error after ArtikelAppAuthFlow:");
                            console.log(error);
                            this.logout();
                        }
                    );
                }
                catch (error) {
                    console.log("error before ArtikelAppAuthFlow:");
                    console.log(error);
                }
            },
            /**
             * Create a alert pop up message
             * @param message 
             * @param type 
             */
            newAlert(message, type) {
                this.alertMessage = message;
                this.alertType = type;
                this.$refs.alert.newAlert();
            },
        },
        async beforeMount() {
            await this.authFlowArtikelApp().then(() => {
                this.getGPCLevels()
            })
        }
    }        
</script>