<template>
    <v-container fluid>
        <v-text-field label="Nicht zugeordnete Mitarbeiter" prepend-icon="mdi-magnify" height="30px" clearable
            v-model="search">
        </v-text-field>
        <v-data-table :headers="headers" fixed-header :items="this.employee" :items-per-page="10" :footer-props="{
            'items-per-page-options': [10, 20, 30]
        }" class="elevation-1" height="calc((100vh + 100px) / 2" :loading="this.loading" loading-text="Bitte Warten"
            :search="search" :key=renderKey>
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        <v-checkbox v-model="item.selected" @change="handleCheckboxChange(item)"
                            :disabled="item.disabled"></v-checkbox>
                    </td>
                    <td>
                        <p>{{ item.other_name }}</p>
                    </td>
                    <td>
                        <v-icon dense @click="toggleExpand(item)">mdi-arrow-down</v-icon>
                    </td>
                </tr>
                <tr>
                    <td v-if="expanded.includes(item)" :colspan="headers.length">
                        <div width="max" v-if="expanded.includes(item)">
                            <tr :colspan="headers.length">
                                <td style="width: 100%;">
                                    <p>Personalnummer:{{ item.personnel_number }} </p>
                                    <p>System: {{ item.system_name }} </p>
                                </td>
                            </tr>
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <PopupAlert :alertType="alertType" :alertMessage="alertMessage" ref="alert">
        </PopupAlert>
    </v-container>
</template>
<script>

import apiMixin from '@/mixins/apiMixin.js';
import PopupAlert from "@/components/popupAlert.vue";


export default {
    data() {
        return {
            alertType: 'error',
            alertMessage: '',
            employee: [],
            expanded: [],
            echtIdNamesList: [],
            loading: false,
            search: '',
            renderKey: 0,
            headers: [
                { text: "", value: "", sortable: false },
                { text: "Mitarbeitername", value: "other_name" },
                { text: "", value: "", sortable: false },
            ]
        }
    },

    mixins: [apiMixin],

    components: {
        PopupAlert
    },

    props: {
        mappedEmployee:
        {
            type: Object,
            default: () => { }
        },
        unmappedEmployee:
        {
            type: Object,
            default: () => { }
        },
        echtIdNames:
        {
            type: Array,
            default: () => { }
        },
        SelectedGroups:
        {
            type: Array,
            default: () => { }
        },
        defaultSelectedGroup:
        {
            type: Number,
            default: null
        }
    },

    methods: {
        /**
         * Get all unmapped employees
         */
        async getUnmappedEmployees() {
            this.loading = true;
            let response;
            try {
                if(this.SelectedGroups.length == 0) {
                    this.newAlert("Es muss ein Objekt ausgewählt werden", "error");
                    this.employee = [];
                    return;
                }
                if(this.SelectedGroups.length == 1) {
                    response = await this.api.get(`GetUnmappedEmployees?echtId=` + this.defaultSelectedGroup);
                }
                else {
                    let queryString = this.SelectedGroups.map(id => `echtId=${id}`).join('&');
                    response = await this.api.get(`GetUnmappedEmployees?${queryString}`);
                }
                this.employee = response.data;
                this.employee = this.employee.map(emp => ({
                    ...emp,
                    selected: false,
                    disabled: false,
                    company: this.getCompanyName(emp.EchtId)
                }));
            } catch (error) {
                this.newAlert("Fehler beim Laden der nicht zugeordneten Mitarbeiter", "error");
            } finally {
                this.loading = false;
            }
        },

        /**
         * Delete employee from list for showing in data table
         */
        deleteEmployeeFromList() {
            this.handleCheckboxChange(this.mappedEmployee);
            this.employee = this.employee.filter(emp =>
            (emp.mitarbeiter_mapping_key !== this.mappedEmployee.mitarbeiter_mapping_key));
            this.renderKey++;
        },

        /**
         * Add employee to list for showing in data table
         */
        addEmployeeToList() {
            this.employee.unshift(this.unmappedEmployee);
        },

        /**
         * Handle checkbox change
         * @param selectedItem 
         */
        handleCheckboxChange(selectedItem) {
            this.employee.forEach(item => {
                if (item.personnel_number !== selectedItem.personnel_number) {
                    item.disabled = !item.disabled
                }
            });
            // Emit empyt employee if selected is false
            if (selectedItem.selected) {
                selectedItem.selected = false;
                selectedItem.disabled = false;  
                this.$emit('target_employee', selectedItem);
            } else {
                this.$emit('target_employee', '');
            }
        },

        /**
         * Change extend row flag
         * @param item 
         */
         toggleExpand(item) {
            if (this.expanded.includes(item)) {
                this.expanded = this.expanded.filter(expandedItem => expandedItem !== item);
            } else {
                this.expanded = [item];
            }
        },

        /**
         * Get company name by echtId
         * @param echtId 
         * @returns 
         */
        getCompanyName(echtId) {
            const company = this.echtIdNamesList.find(echtIdName => echtIdName.EchtId == echtId);
            return company ? company.Name : "Nicht vorhanden";
        },
        /**
         * Create a alert pop up message
         * @param message 
         * @param type 
         */
        newAlert(message, type) {
            this.alertMessage = message;
            this.alertType = type;
            this.$refs.alert.newAlert();
        },
    },

    watch: {
        mappedEmployee: function () {
            this.deleteEmployeeFromList();
        },
        unmappedEmployee: function () {
            this.addEmployeeToList();
        },
        echtIdNames: function () {
            this.echtIdNamesList = this.echtIdNames;
        },
        SelectedGroups: async function () {
            await this.getUnmappedEmployees();
        },
    },

    async created() {
        await this.getUnmappedEmployees();
    }
}
</script>