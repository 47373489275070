<template>
    <div class="text-center">
        <v-dialog v-model="dialog" max-width="600px">
            <v-card>
                <v-card-title class="text-h5 orange lighten-2">
                    Wirklich speichern?
                </v-card-title>
                <v-card-text class="mt-6">
                    Möchten Sie die Änderungen wirklich speichern?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="saveChanges">
                        Speichern
                    </v-btn>
                    <v-btn color="primary" text @click="dialog = false">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialog: false
        }
    },
    methods: {
        saveChanges() {
            this.$emit('saveChanges');
            this.dialog = false;
        },
        openDialog() {
            this.dialog = true;
        }
    }
}
</script>