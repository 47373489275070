export default {

    getMonate() {
        return [
            { value: 1, text: "Januar" },
            { value: 2, text: "Februar" },
            { value: 3, text: "März" },
            { value: 4, text: "April" },
            { value: 5, text: "Mai" },
            { value: 6, text: "Juni" },
            { value: 7, text: "Juli" },
            { value: 8, text: "August" },
            { value: 9, text: "September" },
            { value: 10, text: "Oktober" },
            { value: 11, text: "November" },
            { value: 12, text: "Dezember" },
            { value: 13, text: "Dezember (Abschluss)" },
        ];
    },

    getJahre() {
        return [
            2018,
            2019,
            2020,
            2021,
            2022,
            2023,
            2024,
            2025,
            2026,
            2027,
            2028,
            2029,
            2030,
            2031,
            2032,
            2033,
            2034,
            2035,
            2036,
            2037,
            2038,
            2039,
            2041,
            2042,
            2043,
            2044,
            2045,
            2046,
            2047,
            2048,
        ];
    }
}
