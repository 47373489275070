<template>
  <v-container fluid>
    <v-row>
      <v-dialog eager v-model="dialog" scrollable max-width="800">
        <v-form ref="form">
          <v-card class="pa-0 ma-0">
            <v-toolbar dark>
              <v-toolbar-title>Konten zuweisen...</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="cancel()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-sheet class="px-4 ma-0">
              <p class="mt-2 mb-0" style="font-size: small">Quelle: Susa</p>
              <table
                class="mt-0"
                style="max-height: 60px; overflow-y: scroll; display: block"
              >
                <tr v-for="susaItem in susaItems" :key="susaItem.susaKontoNr">
                  <td class="susaItem">
                    {{ susaItem.susaKontoNr }}
                  </td>
                  <td style="width: 10px"></td>
                  <td class="susaItem">
                    {{ susaItem.susaKontoBezeichnung }}
                  </td>
                </tr>
              </table>
              <v-divider class="mt-2 mb-2"></v-divider>
              <span style="font-size: small">Ziel: BWA</span>
              <v-text-field
                class="mt-0 pt-2 mb-0"
                label="BWA-Konto suchen"
                v-model="search"
                @keyup="filterData()"
                solo-inverted
                clearable
                rounded
                clear-icon="mdi-close-circle-outline"
              ></v-text-field>
            </v-sheet>
            <v-card-text class="pt-0" style="height: 600px">
              <bwaTreeview
                :allTreeItems="allTreeItems"
                ref="bwaTreeview"
              ></bwaTreeview>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="cancel"> Abbrechen </v-btn>
              <v-btn color="success" @click="assign"> Zuweisen </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
        <v-overlay opacity="0" v-if="loading">
          <v-progress-circular
            indeterminate
            color="red"
            size="64"
          ></v-progress-circular>
        </v-overlay>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<style scoped>
.susaItem {
  font-size: small;
  font-weight: bold;
}
</style>
<script>
import bwaTreeview from "../other/BwaTreeview.vue";

export default {
  name: "selectBWADialog",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    bwaTreeview,
  },

  created() {},
  data: () => ({
    filterRootBwaId: null,
    dialog: false,
    loading: false,
    search: "",
    activeBwaIds: [],
    susaItems: [],
  }),
  props: {
    allTreeItems: Array,
  },
  methods: {
    setRootFilter(setRootFilter) {
      const treeview = this.$refs.bwaTreeview;
      treeview.setRootFilter(setRootFilter);
    },

    // async initializeData(data) {
    //   const me = this;
    //   const treeview = me.$refs.bwaTreeview;
    //   treeview.initializeData(data);
    // },

    show(susaItems) {
      const me = this;
      me.susaItems = susaItems;
      me.$refs.bwaTreeview.openRootNode();
      me.dialog = true;
    },

    clearAndReset() {
      const me = this;
      me.search = "";
      me.$refs.bwaTreeview.resetTree();
    },

    selectNode(bwaId) {
      const me = this;
      me.$refs.bwaTreeview.selectNode(bwaId);
    },

    async assign() {
      const me = this;
      const item = this.$refs.bwaTreeview.getSelectedItem();
      if (!item) {
        me.$alert({
          text: "Bitte BWA-Konto auswählen.",
          dialogMaxWidth: 250,
        });
        return;
      }
      if (item.ebene != 4) {
        me.$alert({
          text: "Bitte ein BWA-Konto auf Ebene 4 auswählen.",
          dialogMaxWidth: 250,
        });
        return;
      }
      me.$emit("bwaSelected", {
        susaItems: me.susaItems,
        bwaItem: item,
        bwaHauptkontoId: item.bwaHauptkontoId,
      });
    },

    cancel() {
      this.dialog = false;
    },

    filterData() {
      const me = this;
      me.$refs.bwaTreeview.filterData(me.search);
    },

    showBwaKonto(bwaId, isUpdateMode) {
      const me = this;
      me.isUpdateMode = isUpdateMode;
      me.$refs.bwaTreeview.searchTree(bwaId);
    },
  },
};
</script>

