<template>
    <v-container fluid>
  <v-text-field class="mb-0 mt-2 pb-0"
    label="Suchen"
    dense
    solo-inverted 
    clearable
    v-model="searchText"
    clear-icon="mdi-close-circle-outline"
  ></v-text-field>
  <v-data-table class="mt-0 pt-0" :height="height"
      dense
      fixed-header
      :items-per-page="itemsPerPage"
      :headers="headers"
      :items="items"
      :search="searchText"
      v-model="selectedItems"
      item-key="susaKontoNr"
    >
      <template slot="no-data" v-if="loading == true">
      <v-row><p class="mt-12">Lade Daten...</p></v-row>
      <v-row>
        <v-overlay opacity="0">
          <v-progress-circular
            indeterminate
            color="red"
            size="64"
          ></v-progress-circular>
        </v-overlay> 
      </v-row>
      </template>
      <template slot="no-data" v-if="loading == false">Keine Datensätze gefunden.</template>
      <template v-slot:[`item.bwaId`]='{ item }'>
        <v-btn small width="80" dense v-model="item.bwaId" @click="onAssignBwaClicked(item)">
          <b>{{item.bwaId}}</b>
        </v-btn>
        <span class="ml-2">{{item.bwaBezeichnung}}</span>
      </template> 
      <template v-slot:[`item.commands`]='{ item }'>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn rounded small dense v-bind="attrs"
                      v-on="on"
                      @click="onResetToUnmapped(item)"
              >
                <v-icon small>mdi-arrow-u-left-top</v-icon>
              </v-btn>
            </template>
            <span>Zurücklegen zu Ungemappt</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">            
              <v-btn v-bind="attrs" v-on="on" rounded small dense v-model="item.bwaId" @click="onAssignToUnrelevant(item)"><v-icon small>mdi-square-off-outline</v-icon></v-btn>
            </template>
            <span>Zu -Nicht relevant- aussortieren</span>
          </v-tooltip>
      </template> 
    </v-data-table>
  </v-container>
</template>
<style scoped>  
</style>
<script>

export default {
  name: "mappedSusaTable",
  created() {
    this.headers = this.createHeaders();
  },
  mounted() {
  },
  data: () => ({
    headers: [],
    selectedItems: [],
    itemsPerPage: 10,
    searchText: ''
  }),
  props: {
    items: Array,
    loading: Boolean,
    height: Number
  },
  methods: {


    onAssignBwaClicked(item) {
      this.$emit("assignBwa", {
        item: item, 
        filterRootBwaId : null
      });
    },   

    onResetToUnmapped(item) {
          this.$confirm(
              "Dieses SUSA-Konto wurde bereits zugeordnet. Soll die Zuordnung wirklich entfernt werden?", {
                  title: "Zuordnung entfernen?",
                  icon: "mdi-comment-question-outline",
                  color: '#e30613'
              }
          ).then(res => {
              if (res == true) {
                this.$emit("resetToUnmapped", item);
              }
          });
    },
    
    onAssignToUnrelevant(item) {
          this.$confirm(
              "Dieses SUSA-Konto wurde bereits zugeordnet. Soll die Zuordnung wirklich entfernt und das Konto zu -Nicht relevant- aussortiert werden?", {
                  title: "Zuordnung entfernen?",
                  icon: "mdi-comment-question-outline",
                  color: '#e30613'
              }
          ).then(res => {
              if (res == true) {
                this.$emit("assignToUnrelevant", {
                        item: item
                      });
              }
          });
    },

    createHeaders() {
      const headers = [
        {
          text: "Susa Konto-Nr",
          value: "susaKontoNr",
          width: 130
        },
        {
          text: "Susa Konto",
          value: "susaKontoBezeichnung",
          width: 260
        },
        {
          text: "BWA Konto",
          value: "bwaId",
        },
        {
          text: "Aktionen",
          value: "commands",
          width: 150
        }        
        ];
      return headers;
    },    

  } 

};
</script>

