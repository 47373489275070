<template>
    <v-container>
        <v-dialog v-model="addShow" max-width="600" persistent>
            <v-card>
                <v-card-title class="headline justify-center align-center">Mitarbeiter hinzufügen</v-card-title>
                <v-row no-gutters>
                    <v-col cols="12" sm="10" md="10" offset-sm="1" offset-md="1" class="d-flex flex-column justify-center">
                        <v-text-field
                        v-model="master_first_name"
                        label="Vorname"
                        required></v-text-field>
                    <v-text-field
                        v-model="master_last_name"
                        label="Nachname"
                        required></v-text-field>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-btn color="green darken-1" @click="acceptDialog">Bestätigen</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" @click="closeDialog">Abbrechen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <PopupAlert :alertType="alertType" :alertMessage="alertMessage" ref="alert">
        </PopupAlert>
    </v-container>
</template>

<script>

import apiMixin from '@/mixins/apiMixin.js';
import PopupAlert from "@/components/popupAlert.vue";

export default {
    data() {
        return {
            addShow: false,
            alertType: 'error',
            alertMessage: '',
            master_first_name: '',
            master_last_name: '',
            addedEmployee: {
                master_first_name: '',
                master_last_name: '',
                echt_id: null
            }   
        }
    },

    mixins: [apiMixin],

    components: {
        PopupAlert
    },

    methods: {
        /**
         * Close dialog
         */
        closeDialog() {
            this.addShow = false;
        },

        /**
         * Accept dialog, check if all fields are filled and create the employee
         */
        acceptDialog() {
            if(this.master_first_name == '' || this.master_last_name == '' || this.defaultSelectedGroup == null) {
                this.newAlert('Bitte füllen Sie alle Felder aus', 'error');
                return;
            }
            this.addCreateEmployee();
            this.addedEmployee.master_first_name = this.master_first_name;
            this.addedEmployee.master_last_name = this.master_last_name;
            this.addedEmployee.echt_id = this.defaultSelectedGroup;
            this.$emit('addedEmployee', this.addedEmployee);
            this.addShow = false;
            this.master_first_name = '';
            this.master_last_name = '';
            this.addedEmployee = {
                master_first_name: '',
                master_last_name: '',
                echt_id: null
            }
        },

        /**
         * Open the add employee dialog
         */
        openAddEmployeeDialog() {
            this.addShow = true;
        },

        /**
         * Create a new employee
         */
        addCreateEmployee() {
            try {
                this.api.get('CreateMasterEmployee?master_first_name=' + this.master_first_name + '&master_last_name=' + this.master_last_name + '&echt_id=' + this.defaultSelectedGroup);
            }
            catch (error) {
                this.newAlert('Fehler beim Erstellen des Mitarbeiters', 'error');
            }
        },

        /**
         * Create a alert pop up message
         * @param message 
         * @param type 
         */
        newAlert(message, type) {
            this.alertMessage = message;
            this.alertType = type;
            this.$refs.alert.newAlert();
        },
    },
    props : {
        SelectedGroups:
        {
            type: Array,
            default: () => { }
        },
        defaultSelectedGroup:
        {
            type: Number,
            default: null
        }
    },
}
</script>