<template>
    <v-container fluid>
        <v-text-field label="Zugeordnete Mitarbeiter" prepend-icon="mdi-magnify" height="30px" clearable
            v-model="search">
        </v-text-field>
        <v-data-table 
            :headers="headers" 
            :items="employee" 
            fixed-header 
            :items-per-page="10" 
            :footer-props="{
                'items-per-page-options': [10, 20, 30]
            }" 
            class="elevation-1" 
            height="calc((100vh + 100px) / 2" 
            :loading="this.loading" 
            loading-text="Bitte Warten"
            :search="search">
            <template v-slot:item="{ item, headers }">
                <tr>
                    <td>
                        <v-checkbox v-model="item.selected" @change="handleCheckboxChange(item, true)"
                            :disabled="item.disabled" :ref="item.master_employee_id" :key="checkBoxRenderKey"></v-checkbox>
                    </td>
                    <td>
                        <p>{{ item.master_employee_id }}</p>
                    </td>
                    <td>
                        <p>{{ item.master_name }}</p>
                    </td>
                    <td>
                        <div v-if="item.created == 'True'">
                            <v-icon>mdi-account-multiple-plus-outline</v-icon>
                        </div>
                    </td>
                    <td>
                        <v-icon dense @click="toggleExpand(item)">mdi-arrow-down</v-icon>
                    </td>
                </tr>
                <tr>
                    <td v-if="expanded.includes(item) && item.mapped_employees_for_echt_employee_id.length > 0" :colspan="headers.length">
                        <div width="max" v-if="expanded.includes(item)">
                            <div v-for="subMappedEmployee in item.mapped_employees_for_echt_employee_id"
                                :key="subMappedEmployee.mitarbeiter_mapping_key"
                                style="border-bottom: 1px solid #000; padding-right: 20px;">
                                <tr :colspan="headers.length">
                                    <td class="d-flex flex-column justify-center align-center">
                                        <v-checkbox v-model="subMappedEmployee.selected"
                                            @change="handleCheckboxChange(subMappedEmployee, false)" 
                                            :disabled="subMappedEmployee.disabled"
                                            :ref="subMappedEmployee.mitarbeiter_mapping_key"></v-checkbox>
                                    </td>
                                    <td style="width: 100%;">
                                        <p>Id: {{ subMappedEmployee.master_employee_id }}</p>
                                        <p>Name: {{ subMappedEmployee.other_name }} </p>
                                        <p>Personalnummer:{{ subMappedEmployee.personnel_number }} </p>
                                        <p>System: {{ subMappedEmployee.system_name }} </p>
                                    </td>
                                </tr>
                            </div>
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <PopupAlert :alertType="alertType" :alertMessage="alertMessage" ref="alert">
        </PopupAlert>
    </v-container>
</template>
<script>

import apiMixin from '@/mixins/apiMixin.js';
import PopupAlert from "@/components/popupAlert.vue";

export default {
    data() {
        return {
            alertType: 'error',
            alertMessage: '',
            checkBoxRenderKey: 0,
            employee: [],
            echtIdNamesList: [],
            expanded: [],
            lastMasterEmployeeId: '',
            loading: false,
            mappedEmployeeId: '',
            mappedEmployeeMitarebiterMappingKey: [],
            search: '',
            selectedItem: {},
            headers: [
                { text: "", value: "", sortable: false },
                { text: "Mitarbeiter-ID", value: "master_employee_id" , sortable: true },
                { text: "Mitarbeitername", value: "master_name" , sortable: true },
                { text: "Erstellte Mitarbeiter", value: "created", sortable: true },
                { text: "", value: "data-table-expand", sortable: false }
            ]
        }
    },

    mixins: [apiMixin],

    components: {
        PopupAlert
    },

    props: {
        mappedEmployee:
        {
            type: Object,
            default: () => { }
        },
        unmappedEmployee:
        {
            type: Object,
            default: () => { }
        },
        SelectedGroups:
        {
            type: Array,
            default: () => { }
        },
        defaultSelectedGroup:
        {
            type: Number,
            default: null
        },
        addedEmployee:
        {
            type: Object,
            default: () => ({
                master_first_name: '',
                master_last_name: '',
                echt_id: null
            })
        },
        deletedMasterEmployeeId:
        {
            type: String,
            default: null
        }

    },

    methods: {
        /**
         * Get all master employees
         */
        async getMasterEmployees() {
            this.loading = true;    
            try {
                const response = await this.api.get('GetAllMasterEmployeesByEchtId?echt_id=' + this.defaultSelectedGroup);
                this.employee = response.data;
                await this.getMappedEmployees();
                this.employee = this.employee.map(emp => ({
                    ...emp,
                    master_name: `${emp.master_first_name} ${emp.master_last_name}`,
                    selected: false,
                    disabled: false,
                    mapped_employees_for_echt_employee_id: this.getMappedEmployeesForEchtEmployeeId(emp.master_employee_id)
                }))
            }
            catch (error) {
                this.newAlert("Fehler beim Laden der zugeordneten Mitarbeiter", "error");
            }
            finally {
                this.loading = false;
            }
        },

        /**
         * Get all mapped employees
         */
        async getMappedEmployees() {
            let response;
            try {
                if (this.SelectedGroups.length == 0) {
                    this.newAlert("Es muss ein Objekt ausgewählt werden", "error");
                    this.mappedEmployees = [];
                    return;
                }
                if (this.SelectedGroups.length == 1) {
                    response = await this.api.get(`GetMappedEmployees?echtId=` + this.defaultSelectedGroup);
                }
                else {
                    let queryString = this.SelectedGroups.map(id => `echtId=${id}`).join('&')
                    response = await this.api.get(`GetMappedEmployees?${queryString}`);
                }
                this.mappedEmployees = JSON.parse(JSON.stringify(response.data));
                this.mappedEmployees = this.mappedEmployees.map(emp => ({
                    ...emp,
                    selected: false,
                    disabled: false,
                }))
                this.createMappedEmployeesMitarbeiterMappingKeyList();
                this.$emit('mappedEmployeesMitarbeiterMappingKey', this.mappedEmployeeMitarebiterMappingKey);
            }
            catch (error) {
                this.newAlert("Fehler beim Laden der Mitarbeiter", "error");
            }
        },

        /**
         * Get all mapped employees mitarbeiter mapping keys
         */
        createMappedEmployeesMitarbeiterMappingKeyList() {
            for (let i = 0; i < this.mappedEmployees.length; i++) {
                this.mappedEmployeeMitarebiterMappingKey.push(this.mappedEmployees[i].mitarbeiter_mapping_key);
            }
        },

        /**
         * Get mapped employees for each master employee
         * @param master_employee_id
         */
        getMappedEmployeesForEchtEmployeeId(master_employee_id) {
            return this.mappedEmployees.filter(emp =>
                emp.master_employee_id === master_employee_id);
        },
        /**
         * Change extend row flag
         * @param item 
         */
        toggleExpand(item) {
            if (this.expanded.includes(item)) {
                this.expanded = this.expanded.filter(expandedItem => expandedItem !== item);
            } else {
                this.expanded = [item];
            }
        },

        /**
         * Handle the selection of the checkbox
         * @param selectedItem 
         */
        handleCheckboxChange(selectedItem, master) {
            this.employee.forEach(item => {
                // All master other then the selected master or the master from the selected sub employee should be disabled
                if (item.master_employee_id !== selectedItem.master_employee_id) {
                    item.disabled = !item.disabled
                }
                // If master employee is selected all sub employees should be disabled
                if (master) {  
                    if(item.mapped_employees_for_echt_employee_id.length > 0) {
                        item.mapped_employees_for_echt_employee_id.forEach(mappedEmployee => {
                            mappedEmployee.disabled = !mappedEmployee.disabled
                        });
                    }
                }
                else {
                    if (item.master_employee_id === selectedItem.master_employee_id) {
                    item.disabled = !item.disabled
                }
                    item.mapped_employees_for_echt_employee_id.forEach(mappedEmployee => {
                        if ((mappedEmployee.personnel_number !== selectedItem.personnel_number) || (mappedEmployee.EchtId !== selectedItem.EchtId)) {
                            mappedEmployee.disabled = !mappedEmployee.disabled
                        }
                    });
                }
            });
            // Emit empyt employee if selected is false
            if (selectedItem.selected) {
                selectedItem.selected = false;
                this.$emit('sourceEmployee', selectedItem);
                this.selectedItem = JSON.parse(JSON.stringify(selectedItem));
            } else {
                this.$emit('sourceEmployee', '');
            }
        },

        /**
         * Delete employee from list for showing in data table
         */
        deleteEmployeeFromList() {
            this.employee = this.employee.filter(emp =>
                emp.mapped_employees_for_echt_employee_id = emp.mapped_employees_for_echt_employee_id.filter(emp_sub =>
                    emp_sub.mitarbeiter_mapping_key !== this.unmappedEmployee.mitarbeiter_mapping_key));
            this.enableAllCheckboxes();
        },

        /**
         * Add employee to list for showing in data table
         */
        addEmployeeToList() {
            this.employee.forEach(emp => {
                if (emp.master_employee_id === this.selectedItem.master_employee_id) {
                    emp.mapped_employees_for_echt_employee_id.unshift(this.mappedEmployee);
                    emp.selected = false;
                    this.selectedItem.selected = false;
                    this.selectedItem.disabled = false;
                    this.$refs[this.selectedItem.master_employee_id].internalValue = false
                }
            });
            this.enableAllCheckboxes();
        },

        /**
         * Enable all checkboxes after unmapping
         */
        enableAllCheckboxes() {
            this.employee.forEach(item => {
                item.disabled = false;
                item.selected = false;
                if (item.mapped_employees_for_echt_employee_id.length > 0) {
                    item.mapped_employees_for_echt_employee_id.forEach(mappedEmployee => {
                        mappedEmployee.selected = false;
                        mappedEmployee.disabled = false;
                    });
                }
            });
            this.checkBoxRenderKey += 1;
        },

        /**
         * Get the last master employee id
         */
        async getLastMasterEmployeeId(){
            try {
                const response = await this.api.get('GetLastMasterEmployeeId')
                this.lastMasterEmployeeId = response.data;
            }
            catch (error) {
                this.newAlert('Fehler beim Laden der Mitarbeiter', 'error');
            }
        },

        /**
         * Create a alert pop up message
         * @param message 
         * @param type 
         */
        newAlert(message, type) {
            this.alertMessage = message;
            this.alertType = type;
            this.$refs.alert.newAlert();
        },
    },

    watch: {
        mappedEmployee: {
            handler() {
                this.addEmployeeToList();
            }
        },
        unmappedEmployee: {
            handler() {
                this.deleteEmployeeFromList();
            },
            deep: true
        },
        echtIdNames: function () {
            this.echtIdNamesList = this.echtIdNames;
        },
        SelectedGroups: async function () {
            await this.getMasterEmployees();
        },
        addedEmployee: async function () {
            await this.getLastMasterEmployeeId();
            let newEmployee = JSON.parse(JSON.stringify(this.addedEmployee))
            newEmployee = {
                ...newEmployee,
                master_employee_id: this.lastMasterEmployeeId + 1,
                master_name: `${newEmployee.master_first_name} ${newEmployee.master_last_name}`,
                selected: false,
                disabled: false,
                mapped_employees_for_echt_employee_id: [],
                created: 'True'
            }
            this.employee.unshift(newEmployee);
            this.checkBoxRenderKey += 1;
            
        },
        deletedMasterEmployeeId: function () {
            this.employee = this.employee.filter(emp => emp.master_employee_id.toString() !== this.deletedMasterEmployeeId);
            this.enableAllCheckboxes();
            this.checkBoxRenderKey += 1;
            this.$emit('finishedDelete', 1);
        },
    },

    async created() {
        await this.getMasterEmployees();
    }
}
</script>