<template>
  <v-container fluid v-if="isLoggedIn == true">
    <p>Willkommen</p>
    <p>Sie sind angemeldet als: <b>{{name}}</b></p>
   <!-- <NewsIframe /> -->
  </v-container>
</template>
<script>
//import NewsIframe from "@/components/other/NewsContainer.vue";

export default {
  name: "home-view",
  //components: {NewsIframe},

  mounted() {
    const sessionInfo = localStorage.getObject("sessionInfo");
    if (sessionInfo != null)
    {
      this.name = sessionInfo.KeyCloakParam.KeyCloakUserInfo.LoginName;
      this.$emit("updateCount");
    }
  },
  props: {
    isLoggedIn: Boolean
  },
  data: () => ({
    name: ''
  })
}
</script>