<template>
  <v-container fluid :style="getStyle">
    <template>
      <h3>Konto-Mapping</h3>
      <v-select
        :items="partners"
        outlined
        class="mt-4"
        dense
        style="max-height: 50px; max-width: 500px"
        item-text="name"
        item-value="echtId"
        v-model="selectedPartner"
        return-object
        @change="onPartnerChange()"
      >
        <template v-slot:item="{ item }">
          <v-badge
            v-if="item.unmappedCount != 0"
            color="red"
            :content="item.unmappedCount"
            overlap
          >
            {{ item.name }}
          </v-badge>
          <span v-if="item.unmappedCount == 0">
            {{ item.name }}
          </span>
        </template>
        <template v-slot:selection="{ item }">
          {{ item.name }}
        </template>
      </v-select>
      <v-tabs v-model="tabNo" @change="onTabChanged($event)" color="primary">
        <v-tab
          ><v-icon class="mr-2">mdi-bookshelf</v-icon>Ungemappt
          <v-badge
            v-if="selectedPartner.unmappedCount != 0"
            color="red"
            :content="selectedPartner.unmappedCount"
          ></v-badge>
        </v-tab>
        <v-tab-item :transition="false">
          <unmappedSusaTable
            ref="unmappedSusaTable"
            :items="unmappedItems"
            :loading="loading"
            :height="getHeight"
            @itemSelectionChanged="onUnmappedSelectionChanged($event)"
            @assignBwa="onAssignUnmapped($event)"
            @assignToUnrelevant="onAssignToUnrelevant($event)"
          >
          </unmappedSusaTable>
        </v-tab-item>
        <v-tab><v-icon class="mr-2">mdi-alpha-b-circle</v-icon>Bilanz</v-tab>
        <v-tab-item :transition="false">
          <mappedSusaTable
            :items="bilanzItems"
            :loading="loading"
            :height="getHeight"
            @resetToUnmapped="onResetToUnmapped"
            @assignToUnrelevant="onAssignToUnrelevant($event)"
            @assignBwa="onAssignMapped($event)"
          >
          </mappedSusaTable>
        </v-tab-item>
        <v-tab><v-icon class="mr-2">mdi-alpha-g-circle</v-icon>GuV</v-tab>
        <v-tab-item :transition="false">
          <mappedSusaTable
            :items="guvItems"
            :loading="loading"
            :height="getHeight"
            @resetToUnmapped="onResetToUnmapped"
            @assignToUnrelevant="onAssignToUnrelevant($event)"
            @assignBwa="onAssignMapped($event)"
          >
          </mappedSusaTable>
        </v-tab-item>
        <v-tab
          ><v-icon class="mr-2">mdi-square-off-outline </v-icon>Nicht
          relevant</v-tab
        >
        <v-tab-item :transition="false">
          <unrelevantSusaTable
            :items="unrelevantItems"
            :loading="loading"
            :height="getHeight"
            @resetToUnmapped="onResetToUnmapped"
          >
          </unrelevantSusaTable>
        </v-tab-item>
      </v-tabs>
    </template>
    <selectBWADialog
      ref="selectBWADialog"
      :allTreeItems="bwaStrukturItems"
      @bwaSelected="onBwaSelected($event)"
    ></selectBWADialog>
  </v-container>
</template>
<style scoped>
html {
  overflow-y: auto;
}
</style>

<script>
import selectBWADialog from "../components/dialogs/SelectBWADialog.vue";
import mappedSusaTable from "@/components/other/MappedSusaTable.vue";
import unmappedSusaTable from "@/components/other/UnmappedSusaTable.vue";
import unrelevantSusaTable from "@/components/other/UnrelevantSusaTable.vue";
import apiMixin from "@/mixins/apiMixin.js";
export default {
  name: "konto-mapping-view",

  components: {
    selectBWADialog,
    mappedSusaTable,
    unmappedSusaTable,
    unrelevantSusaTable,
  },

  mixins: [apiMixin],

  data: () => ({
    tabNo: 0,
    selectedPartner: {},
    loading: false,
    unmappedItems: [],
    unrelevantItems: [],
    bilanzItems: [],
    guvItems: [],
    height: null,
    unmappedSelectedItems: [],
    unmappedCount: 0,
    partners: [],
    bwaStrukturItems: [],
    clearSelectedItemsAfterMapping: false,
  }),

  async created() { },

  mounted() {
    this.height = this.getHeight;
    this.loadPartnersAndRefresh();
  },

  methods: {
    async loadPartnersAndRefresh() {
      await this.loadPartners();
      this.refresh();
    },

    onPartnerChange() {
      this.refresh();
    },

    async loadPartners() {
      let echtId = null;
      if (this.selectedPartner) echtId = this.selectedPartner.echtId;

      // Get allowed partners by keycloak
      const sessionInfo = localStorage.getObject("sessionInfo");
      let allowedParnersEchtIds = [];
      sessionInfo.KeyCloakParam.KeyCloakPartners.forEach((partner) => {
        allowedParnersEchtIds.push(partner.EchtId);
      });
      const res = await this.api.get("GetPartnersAndUnmappedCount");

      // Filter partners based on permissions
      this.partners = res.data.filter((partner) =>
        allowedParnersEchtIds.includes(partner.echtId)
      );
      if (echtId)
        this.selectedPartner = this.partners.find((x) => x.echtId == echtId);
      else this.selectedPartner = this.partners[0];
    },

    onUnmappedSelectionChanged(selectedItems) {
      this.unmappedSelectedItems = selectedItems;
      this.$emit("updateCommands");
    },

    async refresh() {
      const me = this;
      if (me.bwaStrukturItems == null || me.bwaStrukturItems.length == 0) {
        const res = await this.api.get("GetBWAStruktur");
        me.bwaStrukturItems = res.data;
      }
      if (me.selectedPartner == null) {
        await me.loadPartners();
      }
      if (this.tabNo == 0) {
        me.unmappedItems = [];
        me.unmappedItems = await this.loadUnmapped();
        return;
      }
      if (this.tabNo == 1) {
        me.bilanzItems = [];
        me.bilanzItems = await this.loadMapped("0");
        return;
      }
      if (this.tabNo == 2) {
        me.guvItems = [];
        me.guvItems = await this.loadMapped("1");
      }
      if (this.tabNo == 3) {
        me.unrelevantItems = [];
        me.unrelevantItems = await this.loadUnrelevant();
      }
    },

    mapToBilanz_isDisabled() {
      return (
        this.tabNo != 0 ||
        this.unmappedSelectedItems == null ||
        this.unmappedSelectedItems.length == 0
      );
    },

    mapToBilanz_isInvisible() {
      return this.tabNo != 0;
    },

    mapToBilanz() {
      this.clearSelectedItemsAfterMapping = true;
      const dlg = this.$refs.selectBWADialog;
      dlg.setRootFilter("0");
      dlg.clearAndReset();
      dlg.show(this.unmappedSelectedItems);
    },

    mapToGuv() {
      this.clearSelectedItemsAfterMapping = true;
      const dlg = this.$refs.selectBWADialog;
      dlg.setRootFilter("1");
      dlg.clearAndReset();
      dlg.show(this.unmappedSelectedItems);
    },

    mapToGuv_isDisabled() {
      return (
        this.unmappedSelectedItems == null ||
        this.unmappedSelectedItems.length == 0
      );
    },

    mapToGuv_isInvisible() {
      return this.tabNo != 0;
    },

    async setNotRelevant() {
      this.clearSelectedItemsAfterMapping = true;
      await this.doAssignToUnrelevant(
        this.unmappedSelectedItems.map((x) => x.susaKontoNr)
      );
    },

    setNotRelevant_isDisabled() {
      return (
        this.tabNo != 0 ||
        this.unmappedSelectedItems == null ||
        this.unmappedSelectedItems.length == 0
      );
    },

    setNotRelevant_isInvisible() {
      return this.tabNo != 0;
    },

    async onTabChanged() {
      const me = this;
      setTimeout(() => {
        me.refreshAndUpdateCommands();
       }, 20);
    },

    async refreshAndUpdateCommands() {
      await this.refresh();
      this.$emit("updateCommands"); //To update command enabling/disabling in menu
    },

    onAssignUnmapped(e) {
      this.clearSelectedItemsAfterMapping = true;
      let item = e.item;
      let selectedItems = [];
      selectedItems.push(item);
      const dlg = this.$refs.selectBWADialog;
      dlg.setRootFilter(e.filterRootBwaId);
      dlg.clearAndReset();
      dlg.show(selectedItems);
    },

    onAssignMapped(e) {
      let item = e.item;
      let selectedItems = [];
      selectedItems.push(item);
      const dlg = this.$refs.selectBWADialog;
      dlg.setRootFilter(null);
      dlg.clearAndReset();
      dlg.show(selectedItems);
      dlg.selectNode(item.bwaId);
    },

    async onResetToUnmapped(e) {
      const me = this;
      let item = e;
      await this.api.post("ResetToUnmapped", {
        susaKonto: item.susaKontoNr,
        echtId: me.selectedPartner.echtId,
      });
      this.refresh();
      this.updateCount();
    },

    async updateCount() {
      const me = this;
      const res = await this.api.get("GetUnmappedCountByPartner", {
        params: {
          echtId: me.selectedPartner.echtId,
        },
      });
      this.selectedPartner.unmappedCount = res.data;

      //To update total unmapped count in menu
      this.$emit("updateCount");
    },

    async onAssignToUnrelevant(e) {
      await this.doAssignToUnrelevant([e.item.susaKontoNr]);
    },

    async doAssignToUnrelevant(susaKonten) {
      const me = this;
      const res = await this.api.post("AssignToUnrelevant", {
        susaKonten: susaKonten,
        echtId: me.selectedPartner.echtId,
      });
      if (res.status == 200) {
        me.$refs.unmappedSusaTable.selectedItems = [];
        me.unmappedSelectedItems = [];
        me.refresh();
        me.updateCount();
      }
    },

    onBwaSelected(e) {
      const me = this;

      const firstItem = e.susaItems[0];
      if (firstItem.id) {
        me.$confirm("Wirklich anderes BWA-Konto zuweisen?", {
          title: "Anderes BWA-Konto zuweisen",
        }).then((res) => {
          if (res === true) {
            me.updateMappings(e);
          }
        });
      } else {
        me.insertMappings(e);
      }
    },

    async insertMappings(e) {
      const me = this;
      const dlg = this.$refs.selectBWADialog;
      dlg.loading = true;
      const res = await this.api.post("InsertMappings", {
        bwaId: e.bwaItem.bwaId,
        bwaHauptkontoId: e.bwaHauptkontoId,
        susaKonten: e.susaItems.map((x) => x.susaKontoNr),
        echtId: me.selectedPartner.echtId,
      });
      dlg.loading = false;
      if (res.status == 200) {
        if (me.clearSelectedItemsAfterMapping) {
          me.unmappedSelectedItems = [];
        }
        me.$refs.unmappedSusaTable.selectedItems = [];
        const dlg = this.$refs.selectBWADialog;
        dlg.dialog = false;
        me.refresh();
        me.$emit("updateCommands"); //To update command enabling/disabling in menu
        me.updateCount();
        me.loadPartners(); //To update unmapped counts in patrtner selection
        return;
      } else {
        this.$error("Beim Speichern ist ein Fehler is aufgetreten.");
      }
    },

    async updateMappings(e) {
      const me = this;
      const res = await this.api.post("UpdateMappings", {
        bwaId: e.bwaItem.bwaId,
        bwaHauptkontoId: e.bwaHauptkontoId,
        mappedIds: e.susaItems.map((x) => x.id),
      });
      if (res.status == 200) {
        me.refresh();
        const dlg = this.$refs.selectBWADialog;
        dlg.dialog = false;
        return;
      }
    },

    async loadUnmapped() {
      const me = this;
      try {
        me.loading = true;
        let res;
        try {
          res = await this.api.get(
            `GetUnmappedKonten?echtId=${me.selectedPartner.echtId}`
          );
        } catch (err) {
          this.$alert({
            title: "Fehler beim Laden",
            text: "Beim Laden ist ein Fehler aufgetreten: " + err + (err.response != undefined && err.response.data != undefined ? "\nDetails: " + err.response.data : ""),
            dialogMaxWidth: 400,
            acceptText: "Schliessen",
          });
          return;
        }
        if (res.status != 200) {
          console.log("Fehler beim Laden:");
          console.log(res);
        }
        return res.data;
      } finally {
        me.loading = false;
      }
    },

    async loadMapped(bwaHauptkontoId) {
      const me = this;
      try {
        me.loading = true;
        const res = await this.api.get("GetMappedKonten", {
          params: {
            echtId: me.selectedPartner.echtId,
            bwaHauptkontoId: bwaHauptkontoId,
          },
        });
        return res.data;
      } finally {
        me.loading = false;
      }
    },

    async loadUnrelevant() {
      const me = this;
      try {
        me.loading = true;
        let res;
        try {
          res = await this.api.get(
            `GetUnrelevanteKonten?echtId=${me.selectedPartner.echtId}`
          );
        } catch (err) {
          this.$alert({
            title: "Fehler beim Laden",
            text: "Beim Laden ist ein Fehler aufgetreten: " + err + (err.response != undefined && err.response.data != undefined ? "\nDetails: " + err.response.data : ""),
            dialogMaxWidth: 400,
            acceptText: "Schliessen",
          });
          return;
        }
        if (res.status != 200) {
          console.log("Fehler beim Laden:");
          console.log(res);
        }
        return res.data;
      } finally {
        me.loading = false;
      }
    },
  },

  computed: {
    getHeight() {
      return this.contentHeight - 270;
    },

    getStyle() {
      return `background-color:transparent;width:100%;height:${this.contentHeight}px`;
    },
  },
};
</script>