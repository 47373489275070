<template>
  <v-container fluid v-if="isLoggedIn == true">
    <h3>Partner App</h3>
    <v-tabs v-model="tab" v-if="isEditor == true">
      <v-tab>
        Stammdaten
      </v-tab>
      <v-tab>
        Verbinden
      </v-tab>
      <v-tab :disabled="tab !== 2">
        Lieferantendetails
      </v-tab>
    </v-tabs>
    <v-tabs v-model="tab" v-if="isEditor == false">
      <v-tab>
        Betrachten
      </v-tab>
    </v-tabs>
    <!-- The componetns  -->
    <PartnerMasterData v-if="tab === 0 && isEditor == true"></PartnerMasterData>
    <PartnerMerge v-if="tab === 1 && isEditor == true"></PartnerMerge>
    <PartnerDetails v-if="tab === 2 && isEditor == true" :echtPartnerId="detailPartnerId" @updatePartner="
      changeTab(0)">
    </PartnerDetails>
    <PartnerViewer v-if="tab === 0 && isEditor == false"></PartnerViewer>
  </v-container>
</template>
<script>
import axios from "axios";

import PartnerMasterData from "@/components/partnerApp/partnerAppTabs/PartnerMasterData.vue";
import PartnerDetails from "@/components/partnerApp/partnerAppTabs/PartnerDetails.vue";
import PartnerMerge from "@/components/partnerApp/partnerAppTabs/PartnerMerge.vue";
import PartnerViewer from "@/components/partnerApp/partnerAppTabs/PartnerViewer.vue";

const emitter = require('tiny-emitter/instance');

export default {
  name: "partner-app-view",

  mounted() {
    const sessionInfo = localStorage.getObject("sessionInfo");
    if (sessionInfo != null) {
      this.name = sessionInfo.KeyCloakParam.KeyCloakUserInfo.LoginName;
    }

    emitter.on('editParentPartner', async (editParentPartnerId) => {
      console.log('editParentPartner', editParentPartnerId);
      this.detailPartnerId = editParentPartnerId;
      this.tab = 2;
    });

    this.checkIfUserIsEditor();
  },
  props: {
    isLoggedIn: Boolean
  },
  data: () => ({
    name: '',
    tab: 0,
    detailPartnerId: null,
    api: axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
      timeout: 100000000
    }),
    isEditor: false
  }),
  components: {
    PartnerMasterData,
    PartnerDetails,
    PartnerMerge,
    PartnerViewer
  },
  methods: {
    changeTab(tab) {
      this.tab = tab;
      console.log('changeTab', tab);
    },
    checkIfUserIsEditor() {
      const sessionInfo = localStorage.getObject("sessionInfo");
      const userRoles = sessionInfo.KeyCloakParam.KeyCloakUserRoles;
      const isEditor = userRoles.some(role => role.Role === 'partner_app_editor'); //TODO: Leider ist der Rollenname hart kodiert
      if (isEditor) {
        this.isEditor = true;
      } else {
        this.isEditor = false;
      }
    }
  },
  watch: {
    tab(val) {
      if (val === 0) {
        emitter.emit('refreshParent');
      }
    }
  }
}
</script>

<style scoped>
.margin-top {
  margin-top: 20px;
}
</style>
