import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueScreenSize from "vue-screen-size";
import DialogPromise from "vuetify-dialog-promise";
import Clipboard from "v-clipboard";
import responsiveMixin from "./mixins/responsiveMixin";
import "./registerServiceWorker";

Storage.prototype.setObject = function (key, value) {
  this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function (key) {
  var value = this.getItem(key);
  return value && JSON.parse(value);
};
Vue.use(VueScreenSize);

Vue.config.productionTip = false;

Vue.use(DialogPromise, {
  locale: "de",
  snackbarX: "center",
  snackbarY: "bottom",
  snackbarTimeout: 6000,
  dialogMaxWidth: 800,
});

Vue.use(Clipboard);

Vue.config.errorHandler = (err, vm, info) => {
  // err: error trace
  console.log("Vue central error handler");
  console.log(err);
  console.log(vm);
  console.log(info);
  //this.$alert(err);
  if (err.message && err.message == "Network Error") {
    vm.showError(
      "Der API-Server ist nicht verfügbar. Bitte versuchen Sie es später erneut."
    );
    return;
  }
};

Vue.prototype.$versionNo = '20241021.1';
Vue.prototype.$smallAppBarHeight = 24;
Vue.prototype.$normalAppBarHeight = 48;
Vue.mixin(responsiveMixin);

new Vue({
  router,
  vuetify,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
