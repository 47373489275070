<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-call-split</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="text-h5 red lighten-2">
                    Achtung
                </v-card-title>

                <v-card-text v-if="item.ECHT_PARTNER_ID != item.ECHT_PARTNER_ID_PARENT" class="mt-6">
                    <a>Wollen Sie den wirklich <strong>{{ item.short_name }}</strong> mit der ECHT Partner Id
                        <strong>{{
            item.ECHT_PARTNER_ID }}</strong> von seinem Parent (<strong>{{ item.ECHT_PARTNER_ID_PARENT
                            }}</strong>)
                        trennen?</a>
                </v-card-text>

                <v-card-text v-else class="mt-6">
                    <a>Der Partner <strong>{{ item.short_name }}</strong> mit der ECHT Partner Id <strong>{{
            item.ECHT_PARTNER_ID }}</strong> ist ein Parent. Er kann nicht getrennt werden.</a>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="item.ECHT_PARTNER_ID != item.ECHT_PARTNER_ID_PARENT" color="red" text
                        @click="splitPartner">
                        Trennen
                    </v-btn>
                    <v-btn color="primary" text @click="dialog = false">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        item: Object
    },
    data() {
        return {
            dialog: false,
            api: axios.create({
                baseURL: process.env.VUE_APP_BASE_URL,
                timeout: 100000000
            })
        }
    },
    methods: {
        async splitPartner() {
            await this.api.patch('RemoveEchtPartnerChildFromParent?childId=' + this.item.ECHT_PARTNER_ID, {
                ECHT_PARTNER_ID: this.item.ECHT_PARTNER_ID
            }).then(response => {
                this.$emit('splitPartner');
                console.log(response.data);
                this.dialog = false;
            });
        }
    }
}
</script>