<template>
  <v-treeview
    class="mt-0"
    item-key="bwaId"
    item-text="bwaIdUndText"
    activatable
    hoverable
    dense
    :items="treeItems"
    :open.sync="openIds"
    :active.sync="activeIds"
    color="grey darken-4"
  >
    <template v-slot:label="{ item }">
      <span v-if="item.ebene == 1" class="text-subtitle-1"
        ><b>{{ item.text }}</b></span
      >
      <span v-else
        ><b>{{ item.bwaId }}</b
        >: {{ item.text }}</span
      >
    </template>
    <template v-slot:prepend="{ item }">
      <v-icon color="green" v-if="item.ebene == 1">
        {{ "mdi-numeric-1-circle" }}
      </v-icon>
      <v-icon color="green" v-if="item.ebene == 2">
        {{ "mdi-numeric-2-circle" }}
      </v-icon>
      <v-icon color="green" v-if="item.ebene == 3">
        {{ "mdi-numeric-3-circle" }}
      </v-icon>
      <v-icon color="green" v-if="item.ebene == 4">
        {{ "mdi-numeric-4-circle" }}
      </v-icon>
    </template>
  </v-treeview>
</template>
<style scoped>
</style>
<script>
export default {
  name: "bwaTreeview",
  created() {},
  mounted() {
    this.initializeData();
  },
  props: {
    allTreeItems: Array,
  },
  data: () => ({
    filterRootBwaId: null,
    isDataInitialized: false,
    prefilteredTreeItems: [],
    flatItems: [],
    foundIds: [],
    activeIds: [],
    treeItems: [],
    openIds: [],
  }),
  methods: {
    setRootFilter(filterRootBwaId) {
      this.filterRootBwaId = filterRootBwaId;
      this.resetTree();
    },

    getAllTreeItems() {
      const me = this;
      if (this.filterRootBwaId) {
        return me.allTreeItems.filter((x) => x.bwaId == this.filterRootBwaId);
      }
      return me.allTreeItems;
    },

    initializeData() {
      const me = this;
      me.treeItems = me.getAllTreeItems();
      me.flatItems = me.flattenTree();
      me.isDataInitialized = true;
    },

    resetTree() {
      const me = this;
      me.treeItems = me.getAllTreeItems();
      me.flatItems = me.flattenTree();
      this.openIds = [];
      this.activeIds = [];
      this.foundIds = [];
    },

    openRootNode() {
      if (this.treeItems && this.treeItems[0]) {
        this.openIds.push(this.treeItems[0].bwaId);
      }
    },

    selectNode(bwaId) {
      const me = this;
      me.openIds = [];
      me.activeIds = [];
      me.treeItems = me.getAllTreeItems();
      me.openIds.push(bwaId);
      me.activeIds.push(bwaId);
      me.treeItems.forEach((element) => {
        me.searchTreeRec(element, bwaId);
      });
    },

    filterData(searchText) {
      const me = this;
      let filteredItems = [];
      me.openIds = [];
      me.activeItem = null;
      me.foundIds = [];

      //Collect matching items in filteredItems
      this.allTreeItems.forEach((element) => {
        //if (element.bwaIdUndText.toLowerCase().includes(searchText.toLowerCase())) {
        if (element.bwaIdUndText.includes(searchText)) {
          filteredItems.push(element);
          me.foundIds.push(element.bwaId);
        }
        this.filterDataRec(element, filteredItems, searchText);
      });

      //2. Expand filteredItems with parent nodes
      filteredItems.forEach((element) => {
        me.expandWithParent(element, filteredItems);
      });

      //3. Recreate filtered tree
      me.treeItems = me.recreateFilteredTree(filteredItems);
      me.openIds = filteredItems.map((x) => x.bwaId);
    },

    getSelectedBwaId() {
      if (!this.activeIds || this.activeIds.length == 0) return null;
      return this.activeIds[0];
    },

    getSelectedItem() {
      const bwaId = this.getSelectedBwaId();
      return this.flatItems.find((x) => x.bwaId == bwaId);
    },

    flattenTree() {
      let flatArray = [];
      this.allTreeItems.forEach((element) => {
        this.flattenTreeRec(element, flatArray);
      });
      return flatArray;
    },

    flattenTreeRec(element, flatArray) {
      const me = this;
      flatArray.push(element);
      element.children.forEach((child) => {
        me.flattenTreeRec(child, flatArray);
      });
    },

    recreateFilteredTree(filteredItems) {
      const me = this;
      let newTreeItems = [];
      this.allTreeItems.forEach((element) => {
        const el = filteredItems.find((x) => x.bwaId == element.bwaId);
        if (el) {
          let newElement = { ...element };
          newElement.children = [];
          newTreeItems.push(newElement);
          me.recreateFilteredTreeRec(element, newElement, filteredItems);
        }
      });
      return newTreeItems;
    },

    recreateFilteredTreeRec(existingParent, newParent, filteredItems) {
      const me = this;
      existingParent.children.forEach((child) => {
        const el = filteredItems.find((x) => x.bwaId == child.bwaId);
        if (el != null) {
          let newChild = { ...child };
          newChild.children = [];
          newParent.children.push(newChild);
          me.recreateFilteredTreeRec(child, newChild, filteredItems);
        }
      });
    },

    searchTreeRec(element, bwaId) {
      const me = this;
      if (element.bwaId == bwaId) {
        this.expandParent(element);
        return true;
      }
      element.children.forEach((child) => {
        if (me.searchTreeRec(child, bwaId)) return true;
      });
      return true;
    },

    filterDataRec(element, filteredItems, searchText) {
      const me = this;
      element.children.forEach((child) => {
        if (child.bwaIdUndText.includes(searchText)) {
          filteredItems.push(child);
          me.foundIds.push(child.bwaId);
        }
        this.filterDataRec(child, filteredItems, searchText);
      });
    },

    expandWithParent(element, filteredItems) {
      const me = this;
      if (element.parentBwaId) {
        const parentElement = me.flatItems.find(
          (x) => x.bwaId == element.parentBwaId
        );
        if (parentElement) {
          filteredItems.push(parentElement);
          me.expandWithParent(parentElement, filteredItems);
        }
      }
    },

    expandParent(element) {
      const me = this;
      if (element.parentBwaId) {
        const parentElement = me.flatItems.find(
          (x) => x.bwaId == element.parentBwaId
        );
        if (parentElement) {
          me.openIds.push(parentElement.bwaId);
          me.expandParent(parentElement);
        }
      }
    },
  },
};
</script>

