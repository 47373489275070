<template>
	<v-app>
		<v-navigation-drawer app dense :mini-variant.sync="mini" :color="sideMenuColor" v-bind:width="240"
			v-model="showSideBar" :mobile-breakpoint="navMobileBreakPoint">
			<v-row v-if="logoVisible">
				<v-col style="height: 110px" class="pa-6">
					<router-link to="/">
						<v-img src="./assets/echt.svg" style="width: 90%; max-height: 90%"></v-img>
					</router-link>
				</v-col>
			</v-row>
			<v-row>
				<sideMenu :DashboardConfig="dashboardConfig" :HasGastronoviaPartner="hasGastronoviaPartner"
					:IsLoggedIn="isLoggedIn" :PartnerDictionary="partnerDictionary" :Partners="partners"
					:UnmappedCount="unmappedCount" :key="sideMenuKey" @SelectedGroupsChange="onSelectedGroupsChange">
				</sideMenu>
			</v-row>
		</v-navigation-drawer>

		<v-app-bar app ref="appBar" dense :height="appBarHeight">
			<v-tooltip bottom color="primary">
				<template v-slot:activator="{ on, attrs }">
					<v-app-bar-nav-icon v-bind="attrs" v-on="on"
						@click="showSideBar = !showSideBar"></v-app-bar-nav-icon>
				</template>
				<span>Navigationsmenu ein-/ausblenden</span>
			</v-tooltip>
			<menu-command CommandName="refresh" IconName="mdi-refresh" TooltipText="Daten neu laden"
				@Execute="onMenuCommandExecute($event)">
			</menu-command>
			<menu-command CommandName="fullscreen" IconName="mdi-fullscreen" TooltipText="Im Vollbild anzeigen"
				@Execute="onMenuCommandExecute($event)">
			</menu-command>
			<menu-command CommandName="upload" IconName="mdi-file-upload" TooltipText="Datei hochladen..."
				@Execute="onMenuCommandExecute($event)">
			</menu-command>

			<menu-command CommandName="mapToBilanz" IconName="mdi-alpha-b-box"
				TooltipText="Selektierte Einträge zu Bilanz-Konto zuweisen..." @Execute="onMenuCommandExecute($event)">
			</menu-command>

			<menu-command CommandName="mapToGuv" IconName="mdi-alpha-g-box"
				TooltipText="Selektierte Einträge zu GuV-Konto zuweisen..." @Execute="onMenuCommandExecute($event)">
			</menu-command>

			<menu-command CommandName="setNotRelevant" IconName="mdi-square-off-outline"
				TooltipText="Als 'Nicht relevant' aussortieren" @Execute="onMenuCommandExecute($event)">
			</menu-command>

			<v-btn-toggle v-if="showViewToggle" v-model="currentView" color="primary" @change="onSwitchView($event)">
				<v-btn>
					<v-icon>mdi-cellphone-text</v-icon>
				</v-btn>
				<v-btn>
					<v-icon>mdi-desktop-mac</v-icon>
				</v-btn>
			</v-btn-toggle>

			<v-spacer></v-spacer>

			<v-menu :offset-y="true" open-on-hover v-if="isLoggedIn">
				<template v-slot:activator="{ on, attrs }">
					<v-btn color="primary" icon dark v-bind="attrs" v-on="on" @click="onUserAccountClick">
						<v-icon>mdi-account</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item v-for="(item, action) in accountMenuItems" :key="action" link
						@click="onUserAccountMenuClick(item.action)">
						<v-list-item-icon class="me-2">
							<v-icon>{{ item.icon }}</v-icon>
						</v-list-item-icon>
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>

		<v-main>
			<div v-if="objectSelectionCount == '1' && selectedGroups.length != 1">
				<v-alert icon="mdi-alert-circle">
					Es muss genau ein Objekt ausgewählt werden.
				</v-alert>
			</div>
			<div v-else-if="objectSelectionCount == '1+' && selectedGroups.length == 0">
				<v-alert icon="mdi-alert-circle">
					Es muss mindestens ein Objekt ausgewählt werden.
				</v-alert>
			</div>
			<router-view v-else ref="routerview" @updateCommands="onUpdateCommands($event)" :isLoggedIn="isLoggedIn"
				:SelectedGroups="selectedGroups" :SelectedGroup="selectedGroup">
			</router-view>
			<!-- TODO Richtig anorden => Mittig -->
			<v-progress-circular v-if="isLoggedIn == false" class="ma-12" :size="50" color="primary"
				indeterminate></v-progress-circular>
			<p v-if="isLoggedIn == false" class="mx-12">Sie werden angemeldet...</p>
		</v-main>
		<v-footer app v-if="footerVisible">
			<span class="footer" style="font-size: 12px">Version {{ $versionNo }}</span>
		</v-footer>
	</v-app>
</template>
<style>
html {
	overflow-y: auto;
}

iframe {
	border: none !important;
}
</style>
<script>
import sideMenu from "./components/other/SideMenu.vue";
import menuCommand from "./components/other/MenuCommand.vue";
import apiMixin from "@/mixins/apiMixin.js";
// eslint-disable-next-line no-unused-vars
import router from "./router/index";
import UploadGlobalView from "./views/UploadGlobalView";
import UploadSusaView from "./views/UploadSusaView";
import KontoMappingView from "./views/KontoMappingView";
import APIManagementView from "./views/APIManagementView";
import ItemMappingView from "./views/ItemMappingView";
import PartnerAppView from "./views/internal/PartnerAppView";
import ArtikelAppView from "./views/internal/ArtikelAppView";
import EmployeeMappingView from "./views/EmployeeMappingView.vue";

import axios from 'axios'

export default {
	name: "App",

	mixins: [apiMixin],

	components: {
		menuCommand,
		sideMenu,
	},

	data: () => ({
		ReportContainerKey: 0,
		accountMenuItems: [],
		currentView: 0,
		dashboardConfig: null,
		objectSelectionCountInfos: [],
		hasGastronoviaPartner: false,
		isAppInitialized: false,
		isLoggedIn: false,
		mini: false,
		partnerDictionary: [],
		partners: [],
		selectedGroups: [0],
		showSideBar: false,
		showViewToggle: false,
		sideMenuColor: null,
		sideMenuKey: 0,
		unmappedCount: 0,
		dynamicRouteComponents: {
			"ItemMappingView": ItemMappingView,
			"APIManagement": APIManagementView,
			"UploadGlobalView": UploadGlobalView,
			"UploadSusaView": UploadSusaView,
			"KontoMappingView": KontoMappingView,
			"PartnerAppView": PartnerAppView,
			"ArtikelAppView": ArtikelAppView,
			"EmployeeMappingView": EmployeeMappingView,
		},
		api_auth: axios.create({
			baseURL: 'http://localhost:7072/api/',
			timeout: 100000000,
		})
	}),

	mounted() {
		this.sideMenuColor = "#fff";
		this.checkLoggedInState().then(
			() => {
				this.getGroupAndObjectId();
				this.getDashboardConfig();
				this.setUnmappedCount();
			}
		);
		this.showSideBar = !this.isMobile;
	},

	updated() {
		document.title = "ECHT Power-BI Portal";
		this.updateCurrentComponentState();
	},

	computed: {
		objectSelectionCount() {
			var c = this.objectSelectionCountInfos.find(x => this.$route.path.startsWith(x.route));
			if (!c || !c.objectSelectionCount) return "0";
			return c.objectSelectionCount;
		},

		selectedGroup: function () {
			return this.selectedGroups[0];
		},

	},

	methods: {
		getContentHeight(vssHeight) {
			let offset = 35;
			let subtractHeight = offset + this.appBarHeight; //134;
			if (this.footerVisible) subtractHeight += 20;
			let height = parseInt(vssHeight) - subtractHeight;
			return height;
		},

		async setUnmappedCount() {
			this.api.get("GetUnmappedCount").then(
				(res) => {
					this.unmappedCount = res.data;
				},
				(error) => this.displayAlert(error)
			);
		},

		onUpdateCommands() {
			this.updateCurrentComponentState();
		},

		checkLoggedInState() {
			return new Promise(
				(resolve, reject) => {
					const sessionInfo = localStorage.getObject("sessionInfo");
					//let accessToken = null;
					let refreshToken = null;
					if (sessionInfo) {
						//accessToken = sessionInfo.KeyCloakParam.KeyCloakToken.access_token;
						refreshToken = sessionInfo.KeyCloakParam.KeyCloakToken.refresh_token;
					}

					if (refreshToken) {
						this.api.get(`PBiAppAuthFlow?refresh=${refreshToken}`).then(
							(data) => {
								if (data && data.status === 200) {
									localStorage.setObject("sessionInfo", data.data);
									this.isLoggedIn = true;
									resolve(data.data);
								} else {
									reject("Something went wrong");
									this.logout();
								}
							},
							(error) => {
								console.log("error after PBiAppAuthFlow width refreshToken:");
								console.log(error);
								localStorage.setObject("sessionInfo", null);
								reject(error);
							}
						);
					}

					this.onMounted();
				}
			);
		},

		_createDictionary(key, value, makeArray) {
			let result = [];
			let resultArray = [];
			value.forEach(
				(currentValue) => {
					if (result[currentValue[key]] === undefined || result[currentValue[key]] === null) {
						result[currentValue[key]] = {
							key: currentValue[key],
							name: currentValue[key],
							values: [],
						};
					}
					result[currentValue[key]].values.push(currentValue);
				}
			);
			if (makeArray) {
				for (const property in result) {
					resultArray.push(result[property])
				}
				return resultArray;
			} else {
				return result;
			}
		},

		_createPartners(value) {
			let result = [];
			for (let key in value) {
				result.push({ key: value[key].key, name: value[key].name });
			}
			return result;
		},

		_registerRoutes() {
			let currentRoute = {};

			this.dashboardConfig.forEach(
				(header, headerKey) => {
					header.values.forEach(
						(currentNavEntry, currentNavEntryKey) => {
							if (currentNavEntry.route !== undefined && currentNavEntry.route !== null) {
								currentRoute = JSON.parse(currentNavEntry.route);
								if (currentRoute !== undefined && currentRoute.component !== undefined && this.dynamicRouteComponents[currentRoute.component] !== undefined) {
									currentRoute.component = this.dynamicRouteComponents[currentRoute.component];
								}
								this.dashboardConfig[headerKey].values[currentNavEntryKey].route = currentRoute;
								router.addRoute(currentRoute);
							}
						}
					)
				}
			);
			return null;
		},

		getDashboardConfig() {
			this.api.get("GetDashboardConfig").then(
				(data) => {
					console.log(data);
					this.dashboardConfig = this._createDictionary('menuHeader', data.data, true);
					console.log("dashboardConfig");
					console.log(this.dashboardConfig);
					this._registerRoutes();
					this.objectSelectionCountInfos = this.getObjectSelectionCountInfos(this.dashboardConfig);
					console.log("this.objectSelectionCountInfos");
					console.log(this.objectSelectionCountInfos);
					this.sideMenuKey += 1;
				},
				(error) => this.displayAlert(error)
			);
		},

		getObjectSelectionCountInfos(dashboardConfig) {
			const result = [];
			dashboardConfig.forEach(category => {
				category.values.forEach(value => {
					if (value.route) {
						if (!result.some(x => x.route === value.route.path)) {
							result.push({
								route: value.route.path,
								objectSelectionCount: value.objectSelectionCount
							});
						}
					}
				});
			});
			result.push({ route: "/report", objectSelectionCount: "1+" }); //workaround since route is null for all reports in dashboardConfig
			return result;
		},

		getGroupAndObjectId() {
			this.api.get("GetPartners").then(
				(data) => {
					this.partnerDictionary = this._createDictionary('groupName', data.data);
					this.partners = this._createPartners(this.partnerDictionary);
					this.sideMenuKey += 1;
				},
				(error) => this.displayAlert(error)
			);
		},

		onSelectedGroupsChange(selectedGroups) {
			this.selectedGroups = selectedGroups;
			// this.selectedGroup = selectedGroups[0];
		},

		displayAlert(error) {
			this.$alert({
				title: "Fehler beim Laden",
				text: "Beim Laden ist ein Fehler aufgetreten: " + error + (error.response != undefined && error.response.data != undefined ? "\nDetails: " + error.response.data : ""),
				dialogMaxWidth: 400,
				acceptText: "Schliessen",
			});
		},

		onLoginClick() {
			window.location.href = process.env.VUE_APP_LOGIN_URL;
		},

		async onMounted() {
			const sessionInfo = localStorage.getObject("sessionInfo");
			if (sessionInfo) {
				this.accountMenuItems = [
					{
						action: "openUserAccount",
						title: sessionInfo.KeyCloakParam.KeyCloakUserInfo.LoginName,
						icon: "mdi-account",
					},
					{
						action: "copyGlobalIdToClipboard",
						title: "Global-ID kopieren",
						icon: "mdi-content-copy",
					},
					{
						action: "logout",
						title: "Abmelden",
						icon: "mdi-logout",
					},
				];
				this.hasGastronoviaPartner =
					sessionInfo.KeyCloakParam.KeyCloakPartners.some(
						(e) => e.GastronoviPartnerId
					);
			}
		},

		onSwitchToMobileView() {
		},

		onSwitchToDesktopView() {
		},


		onSwitchView(viewType) {
			if (!this.$refs.routerview) return;
			if (!this.$refs.routerview.switchView) return;
			this.$refs.routerview.switchView(viewType);
		},

		onUserAccountClick() {
		},

		onUserAccountMenuClick(action) {
			if (action == "logout") this.logout();
			else if (action == "copyGlobalIdToClipboard")
				this.copyGlobalIdToClipboard();
		},

		copyGlobalIdToClipboard() {
			const sessionInfo = localStorage.getObject("sessionInfo");
			this.$clipboard(sessionInfo.KeyCloakParam.KeyCloakUserInfo.GlobalId);
			this.$inform({
				text: "Die Global ID wurde in die Zwischenablage kopiert",
				snackbarTimeout: 3000,
			});
		},

		onMenuCommandExecute(e) {
			if (!this.$refs.routerview) return;
			this.$refs.routerview[e]();
		},
		updateCurrentComponentState() {
			if (!this.$refs.routerview) return;
			const appBar = this.$refs.appBar;
			appBar.$children.forEach((child) => {
				if (child.updateCanExecute != null)
					child.updateCanExecute(this.$refs.routerview);
			});
		},

		async logout() {
			localStorage.setObject("sessionInfo", null);
			this.isLoggedIn = false;
			window.location.href = process.env.VUE_APP_LOGOUT_URL;
		},
	},
};
</script>
