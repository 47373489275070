<template>
    <v-container fluid class="full-height">
        <v-row>
            <v-col cols="12" sm="6" md="6">
                <v-row>
                    <v-col cols="12" sm="5" md="5">
                        <v-text-field v-model="search_parents" label="Parent suche" clearable
                            clear-icon="mdi-close-circle" prepend-icon="mdi-crown"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="7" md="7">
                        <v-text-field v-model="search_singles" label="Partner suche" prepend-icon="mdi-account-search"
                            clearable clear-icon="mdi-close-circle"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="5" md="5">
                        Parent:
                    </v-col>
                    <v-col cols="12" sm="7" md="7">
                        Singles:
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="5" md="5">
                        <v-chip class="ma-2" color="orange" label
                            v-if="selectedParent.ParentID"><v-icon>mdi-crown</v-icon>
                            <strong> {{ selectedParent.short_name }}</strong>
                            <a class="pl-4"> {{ selectedParent.ParentID }}</a>
                        </v-chip>
                    </v-col>
                    <v-col cols="12" sm="7" md="7">
                        <div style="height: 200px; overflow-y: auto; overflow-x: hidden;">
                            <v-chip class="ma-2" color="cyan" label v-for="child in selectedChild"
                                :key="child.ECHT_PARTNER_ID">
                                <v-icon>mdi-account</v-icon>
                                <strong> {{ child.short_name }}</strong>
                                <a class="pl-4">{{ child.ECHT_PARTNER_ID }}</a>
                            </v-chip>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <div class="text-center">
                            <v-btn @click="handleMerge">Zusammenführen</v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="6">
                <div>
                    <parentTable :search="search_parents" @selectParent="handleSelectedParent" :editable="false">
                    </parentTable>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="12">
                <div>
                    <singlesTable :search="search_singles" @selectChild="handleSelectedChild"></singlesTable>
                </div>
            </v-col>
        </v-row>
        <popupAlert :alertType="alertType" :alertMessage="alertMessage" ref="alert">
        </popupAlert>
    </v-container>
</template>

<script>
import axios from 'axios';
const emitter = require('tiny-emitter/instance');

import parentTable from './tabels/parentTable.vue';
import singlesTable from './tabels/singlesTable.vue';

import popupAlert from '../../popupAlert.vue';

export default {
    data() {
        return {
            search_parents: '',
            search_singles: '',
            selectedParent: "",
            selectedChild: "",
            alert: false,
            alertType: 'error',
            alertMessage: '',
            api: axios.create({
                baseURL: process.env.VUE_APP_BASE_URL,
                timeout: 100000000
            })
        };
    },
    components: {
        parentTable,
        singlesTable,
        popupAlert
    },
    methods: {
        handleSelectedParent(item) {
            this.selectedParent = item;
        },
        handleSelectedChild(item) {
            this.selectedChild = item;
        },
        handleMerge() {
            if (!this.selectedParent.ParentID) return this.newAlert('Please select a parent', 'info');
            let parent = this.selectedParent.ParentID.toString();

            if (this.selectedChild.length === 0) return this.newAlert('Please select a child', 'info');
            let children = [...this.selectedChild.map(child => child.ECHT_PARTNER_ID)];

            if (children.includes(parent)) return this.newAlert('Parent cannot be a child', 'error');

            //TODO: Use JSON body instead of query params and make one call to the API

            for (let child of children) {
                this.AddEchtPartnerChildToParent(parent, child);
            }
            this.newAlert('Partners merged successfully', 'success');
            emitter.emit('partnerMerged');
        },
        async AddEchtPartnerChildToParent(parent, children) {
            await this.api.patch('AddEchtPartnerChildToParent?parentId=' + parent + '&childId=' + children).then(response => {
                this.echtPartner = response.data;
            });
        },
        newAlert(message, type) {
            this.alertMessage = message;
            this.alertType = type;
            this.$refs.alert.newAlert();
        }
    }
}
</script>

<style>
.full-height {
    height: calc(100vh - 160px);
}

.half-height {
    /*height: calc((100vh - 160px) / 2);*/
    max-height: calc((100vh - 180px) / 2);
    min-height: calc((100vh - 180px) / 2);
    overflow-y: auto;
}

.scrollable {
    overflow-y: scroll;
    padding: 5px;
}
</style>