<template>
  <v-container fluid >
  <v-row>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="520"
    >
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark>
            <v-toolbar-title>{{title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="cancel()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 220px;">
            <v-autocomplete label="Gastrobetrieb" 
              :items="partners"
              v-model="echtId" 
              item-text="HandelsPartner"
              item-value="EchtId"></v-autocomplete>
            <v-file-input
              ref="fileInput"
                :rules="fileUploadRules"
                class="mt-4"
                accept="text/csv"
                label="CSV-Datei"
                required                
                :value="file"
                @change="onFileChanged($event)"
            ></v-file-input>          
            <v-alert v-if="errorText != ''"
              color="pa-2 ma-0 red lighten-2"
              dark dense
              >
              {{errorText}}
            </v-alert>                
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialog = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="primary"
            @click="upload"
          >
            Hochladen
          </v-btn>
        </v-card-actions>
      </v-card> 
    </v-form>
      <v-overlay opacity="0" v-if="loading">
        <v-progress-circular
          indeterminate
          color="red"
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-dialog>    
  </v-row>
  </v-container>
</template>
<script>
import apiMixin from "@/mixins/apiMixin.js";

export default {
  name: "uploadGlobalDialog",

  mixins: [apiMixin],

  created() {
    const sessionInfo = localStorage.getObject("sessionInfo");
    this.partners = sessionInfo.KeyCloakParam.KeyCloakPartners;
    this.requiredRules.push(v => !!v || "Eingabe erforderlich.");
    this.fileUploadRules = [
        v => !v || v.size < 20000000 || 'Die Datei sollte nicht grösser als 20 MB sein.',
        v => !!v || "Eingabe erforderlich."
    ]
  },
  data: () => ({
    dialog: false,
    loading: false,
    type: '',
    echtId: null,
    requiredRules: [],
    fileUploadRules: [],
    file: null,
    valid: false,
    errorText: '',
    partners: []
  }),
  props: {
    title: String,
  },
  methods: {

    show(echtId, type) {
        this.valid = true;
        this.errorText = false;
        this.file = null;
        this.echtId = echtId;
        this.type = type;
        this.dialog = true;
    },

    onFileChanged(e) {
      this.file = e;
    },

    async upload() {
      const me = this;
      if (!this.$refs.form.validate()) return;

      const params = new URLSearchParams({
        echtId: me.echtId,
        type: me.type,
      }).toString();

      let res;
      try {
        res = await me.api.get(`ExistsGlobalFile?${params}`);
        if (res.data == true) {
          me.$confirm(
              "Diese Datei existiert schon. Überschreiben?", {
                  title: "Datei überschreiben?",
                  icon: "mdi-comment-question-outline",
                  color: '#e30613'
              }
          ).then(res => {
              if (res == true) {
                  this.doUpload(params);
              }
          });
        }
        else {
          this.doUpload(params);
        }
      }
      catch(err) {
        if (err.response.status == 400)
        {
          this.errorText = err.response.data;
          return;
        }
      }
    },

    async doUpload(params) {
      try {
        await this.api.post(`UploadGlobalFile?${params}`, this.file);
      }
      catch(err) {
        if (err.response.status == 400)
        {
          this.errorText = err.response.data;
          return;
        }
        else if (err.response.status == 409)
        {
          this.errorText = "Diese Datei existiert bereits."
          return;
        }
      }
      this.valid = true;
      this.file = null;
      this.$emit("fileUploaded",{ type: this.type, echtId: this.echtId});
      this.dialog = false;
    },


    cancel() {
      this.errorText = "";
      this.dialog = false;
    },

  },
};
</script>

